export const AppBarStyle = {
    listItemButtonStyle: {
        minHeight: 48,
        px: 2.5,
    },
    lastItemListItemStyle: {
        alignItems: "flex-end",
        display: "flex",
        flex: 1
    },
    listItemStyle: {
      alignItems: "flex-end",
      display: "flex",
    },
    dashBoardExpanded: {
        padding: "8px, 16px",
        height: "48px",
        width: "240px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "24px"
    },
    dashBoardCollapsed: {
        width: "40px",
        display: "flex",
        flexDirection: "row",
        marginTop: "24px"
    },
    listStyle: {
        display: "flex", 
        flexDirection: "column", 
        height: "100%", 
        alignItems: "center"
    },
    adminListItemStyle: {
        borderRadius: "4px", 
        width: "240px", 
        height: "56px", 
        paddingLeft: "56px", 
        cursor: "pointer",
    },
    adminItemContainer: {
        minWidth: "184px", 
        height: "56px", 
        padding: "4px 8px",
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    navInnerContainerStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "40px",
        margin: 0,
        padding: "11px"
      },
      
    iconContainerStyle: {
        height: "40px",
        width: "40px",
        padding: "8px",
        color: "#ffffff"
      },
      
       iconButton: {
        height: "40px",
        width: "40px",
        padding: "8px",
        position: "relative",
        top: "5px"
      },
      shortcutContainer: {
        flexDirection: "column",
      borderRadius: "4px",
      border: "1px solid grey",
      padding: "2px",
      gap: "4px",
      maxWidth: "180px",
      maxHeight: "148px",
      alignItems: "center",
      }, 
      shortcutTypography: {
        fontFamily: "Montserrat",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        textAlign: "center"
      },
      typographyContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        bottom: "10px",
      },
      userProfileName: {
        display: "flex",
        flexDiretion: "row",
        justifyContent: "space-evenly",
        width: "100%",
      },
      userProfileRole: {
        display: "flex",
        flexDirection: "column",
        width: "131px",
        height: "auto",
        margin: 0,
      },
      appBarMenuStyle: {
        position: "absolute",
        // display: shortCuts || userProfile ? "flex" : "none",
        flexDirection: "column",
        right: "10px",
        top: "74px",
        zIndex: 3,
        backgroundColor: "background.default",
      },
       backDropStyle: {
        color: "#fff",
        zIndex: 11001,
      },
    
       formLabelStyle: {
        lineHeight: "32.02px",
        marginBottom: "15px",
        color: 'text.primary'
      },
    
       listSubHeaderStyle: {
        fontSize: "0.75rem",
        lineHeight: "31.92px",
        letterSpacing: "1px",
        fontWeight: "500px",
        pl: 0,
        backgroundColor: 'background.default'
      },
    
       closeButtonTextStyle: {
        fontSize: "0.85rem",
        lineHeight: "22px",
        fontWeight: "500px",
        letterSpacing: "0.46px"
      },
      
   titleStyle: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "white",
    letterSpacing: "1.5px",
    // pl: applicantDetails ? '0.8rem' : '0.3rem'
  },
  backDropContainer: {
    backgroundColor: "background.default",
    display: "flex",
    flexDirection: "column",
    right: "0px",
    top: "0px",
    zIndex: 11002,
    padding: "24px",
    position: "absolute",
    width: "20rem",
    height: "100vh"
  },
  backdropButtonContainer: {
    margin: "0px", 
    display: "flex", 
    justifyContent: "flex-end", 
    width: "100%" 
  },
  closeIcon: {
    width: "1rem", 
    height: "1rem", 
    color: 'text.primary'
  }
}