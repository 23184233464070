import { Drawer, Box, Grid, Button } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

interface RightDrawerProps {
    children: any
    openDrawer: boolean
    closeDrawer: any
}
export const RightDrawer: React.FC<RightDrawerProps> = ({
    children,
    openDrawer,
    closeDrawer, 
}) => {
 
    return (
        <Drawer anchor='right' open={openDrawer} onClose={closeDrawer} sx={{ zIndex: 1202}}>
            <Box sx={{height: "100%", width: "600px", padding: 3, backgroundColor: 'background.default'}}>
                <Grid container justifyContent='flex-end' alignItems='center' sx={{ height: "30px", pb: '2rem', position: 'sticky', top: 0 }}>
                    <Button size='small' startIcon={<CloseIcon />} sx={{ color: 'text.primary' }} onClick={closeDrawer}>CLOSE</Button>
                </Grid>
                <Grid sx={{height: "calc(100% - 30px)"}} container display='block' flexDirection='column' justifyContent='center' flexGrow={1}>
                   {children}
                </Grid>
            </Box>
        </Drawer>
    )
}