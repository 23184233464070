
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { urls } from '../urls'
import { formatDate } from "utils/formatDate";
import { transformRejectData } from "../utils";

export const useResultsResponse = (registrationId: string) => {
    const [resultsResponse, setResultsResponse] = React.useState<any>();
    const [rejectData, setRejectData] = useState()
    const [loadingRejectCodes, setLoadingRejectCodes] = useState(false)
    const [totalRejectCodes, setTotalRejectCodes] = useState(0)
    const reportingUrl = urls.REPORTING
    const personUrl = urls.PERSON;

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchReportingsUrl = `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/association/${registrationId}`;

        const fetchUrls = async () => {
            try {
                const resultsResponses: Object[] = [];
                const { associations } = await (await fetch(fetchReportingsUrl, {
                    credentials: "include",
                    signal
                })).json();

                console.log('debug click results associations: ', associations)
                if (associations.length) {
                    associations.filter((association: any) => association['type'] === 'Response').map(async (repAssociation: any) => {
                        let fetchingPersonUrl = `${process.env.NODE_ENV === 'production' ? personUrl : ""}/registration/${repAssociation.id}`;

                        const data = await (await fetch(fetchingPersonUrl, {
                            credentials: "include",
                            signal
                        })).json();

                        console.log('debug click results associations data: ', data)

                        if (data) {
                            const { descriptors } = data;
                            console.log('descriptors.data.type2.field60: ', JSON.stringify({
                                field60Type: typeof descriptors.data.type2.field60,
                                field60: descriptors.data.type2.field60,
                                field60IsObject: typeof descriptors.data.type2.field60 === "object"
                            }, null, 2))
                            if (typeof descriptors.data.type2.field60 === "object") {
                                setLoadingRejectCodes(true)
                                const results = descriptors.data.type2.field60
                                const transformedData = results.map((obj: any, index: number) => { return transformRejectData(obj, index) });
                                setRejectData(transformedData)
                                setTotalRejectCodes(transformedData.length)
                                setLoadingRejectCodes(false)
                            }
                            let type2Field59;
                            if (descriptors.data.type1.field4 !== 'APP' && (descriptors.data.type2.field59 === undefined || descriptors.data.type2.field59 === '')) {
                                type2Field59 = descriptors.data.type1.field4
                            } else {
                                type2Field59 = descriptors.data.type2.field59?.toUpperCase();
                            }
                            const type1Field7 = descriptors.data.type1.field7?.toUpperCase();
                            const date = formatDate(descriptors.data.type1.field5);
                            type2Field59 && resultsResponses.push({ type2Field59, date, type1Field7 });
                        }
                    });
                    setResultsResponse(resultsResponses);
                    console.log('resultsResponses', resultsResponses)
                }

            } catch (err: any) {
                console.log('ERROR => ', err);
            }
        }

        fetchUrls();

        // const refreshUrlInterval = setInterval(() => {
        //     fetchUrls();
        // },60000);

        return () => {
            // clearInterval(refreshUrlInterval);
            controller.abort();
        }
    }, [registrationId, reportingUrl, personUrl])

    return { resultsResponse, rejectData, loadingRejectCodes, totalRejectCodes };
};
