/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import NotesService, { NoteType } from "../utils/notesService";
import { urls } from "../urls";

export type FetchStatuses = "idle" | "loading" | "success" | "error";

const useNotes = (type: NoteType) => {
  const { registrationId = "" } = useParams();
  const [notesJSON, setNotesJSON] = useState<any[]>([]);
  const [fetchStatus, setFetchStatus] = useState<FetchStatuses>("idle");
  const url = urls.NOTES;
  console.log("useNotes url: ", url);

  const notesService = useMemo(() => {
    return new NotesService(registrationId, type, url);
  }, [registrationId, type, url]);

  const memodNotes = useMemo(() => {
    return notesJSON;
  }, [notesJSON]);

  useEffect(() => {
    if (!registrationId) return;
    setFetchStatus("loading");
    (async () => {
      try {
        const data = await notesService.getNotes();
        console.log('debug notes data: ', data);
        setNotesJSON(data["notes"]);
        setFetchStatus("success");
      } catch (error) {
        console.error(error);
        setFetchStatus("error");
      }
    })();
  }, [registrationId, type, notesService]);

  return { notesJSON: memodNotes, fetchStatus };
};

export default useNotes;
