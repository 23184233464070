
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { urls } from '../urls'

type PdfUrls = readonly string[];

const useDashboardUrl = (email: string) => {
    const [dashboardUrl, setDashboardUrl] = React.useState<string>("");
    const url = urls.TRANSACTION
    const controller = new AbortController();
    const { signal } = controller;
    let fetchUrl = `${process.env.NODE_ENV === 'production' ? url : ""}/transaction/embededDashboardUrl`
    const [loadingUrl, setLoadingUrl] = useState(false)

    const fetchEmbedUrl = async () => {
        setLoadingUrl(true)
        try {
            const { embedUrl } = await (await fetch(fetchUrl, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    email,
                    group: "reader"
                }),
                signal
            })).json();

            setDashboardUrl(embedUrl)
            setLoadingUrl(false)

        } catch (err: any) {
            console.log('use dashboard url error: ', err);
        }
    }

    useEffect(() => {
        if (email.includes("@")) fetchEmbedUrl();

        return () => {
            controller.abort();
        }
    }, [email])

    return { dashboardUrl, fetchEmbedUrl, loadingUrl };
};
export default useDashboardUrl;
