import { create } from "zustand"
import { persist, createJSONStorage } from 'zustand/middleware'
import { TableNames } from 'customEnums/TableNames';
import { Config } from "interfaces/config";

export interface UserConfig {
    userORIs: any
    userSecondaryORIs: any
    userRole: any
    userGroup: any
    usersPermissions: any
    groups: any
    userGivenName: any
    userFamilyName: any
    userEmail: any
    loggedIn: boolean,
    isAdmin: boolean | undefined
}

export interface UserState {
    userConfig: UserConfig,
    activeFilters: any,
    config: Config,
    setUserConfig: (userConfig: UserConfig) => void
    setActiveFilters: (activeFilters: any) => void
    setConfig: (config: Config) => void
}

export const useUserStore = create(
    persist(
        (set, get) => ({
            /*_hasHydrated: false,
            setHasHydrated: (state) => {
                set({
                    _hasHydrated: state
                });
            },*/
            userConfig: {
                userORIs: "",
                userSecondaryORIs: "",
                userRole: "",
                userGroup: "",
                usersPermissions: "",
                groups: "",
                userGivenName: "",
                userFamilyName: "",
                userEmail: "",
                loggedIn: false,
                userClosedRbSub: true,
                isAdmin: undefined
            },
            activeFilters: {

                [TableNames.TRANSACTION_SUMMARY]: {
                    filterValues: []
                }

            },
            config: {},
            setConfig: (config: Config) => set({ config }),
            setActiveFilters: (activeFilters: any) => set({ activeFilters }),
            setUserConfig: (userConfig: UserConfig) => set({ userConfig }),
        }),
        {
            name: "userConfig",
            storage: createJSONStorage(() => sessionStorage),
            partialize: (state: any) => {
                console.log('1010101 debug userConfig partialize state: ', state)
                return { userConfig: state.userConfig, config: state.config }
            },
            /*onRehydrateStorage: () => (state: any) => {
                console.log('1010101 debug userConfig onRehyDrate state: ', state)
                state.setHasHydrated(true)
                //state.setUserConfig({ ...state.userConfig, loggedIn: state.loggedIn })
            },*/
            merge: (persistedState: any, currentState) => {
                console.log(`1010101 debug userConfig persistedState ${JSON.stringify(persistedState, null, 2)} currentState ${JSON.stringify(currentState, null, 2)}`)
                console.log(`1010101 debug userConfig merged ${JSON.stringify({ ...currentState, ...persistedState }, null, 2)}`)
                return { ...currentState, ...persistedState }
            },
        }
    )
)