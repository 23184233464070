
export const ApplicantDetailsStyle = {
    card: {
        width: "100%", 
        minHeight: '280px', 
        borderRadius: 1, 
        padding: '24px 0'
    },
    paper: {
        dispaly: "flex",
         flexDirection: "column", 
        width: "100%",
         height: "233px",
         backgroundColor: 'common.black'
    },
    toolBar: {
        height: "68px", 
        paddingTop: 2, 
        paddingLeft: 3, 
        paddingBottom: 2, 
        paddingRight: 3, 
        gap: 2
    },
    container: {
        gap: "16px", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", 
        width: "100%", 
        height: "36px"
    },
    resubmitButton: {
        height: "36px", 
        minWidth: "229px", 
        color: 'text.primary'
    },
    modalContainer: {
        gap: 4, 
        padding: "24px", 
        display: "flex", 
        justifyContent: "space-evenly"
    },
    demographicsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        width: "100%",
    }
}