import { AddUserAction } from './AddUserActions'

export const addNewUserReducer = (state: any, action: any) => {
    console.log('debug addNewUser args: ', JSON.stringify({ state, action }, null, 2))
    const STATE = process.env.REACT_APP_CONFIG_STATE!

    switch (action.type) {
        case AddUserAction.RESET:
            return {
                firstName: '', oriRfpGroups: [
                    {
                        primaryOri: { field: "Primary ORI", value: "" },
                        rfp: { field: "RFP", value: "", required: true }
                    },
                    STATE === "me" && {
                        secondaryOri: { field: "Secondary ORI", value: "" },
                        rfp: { field: "RFP", value: "" }
                    }
                ].filter(Boolean)
            }
        case "First_Name":
            return {
                ...state,
                firstName: action.value
            }
        case "Last_Name":
            return {
                ...state,
                lastName: action.value
            }
        case "User_Name":
            return {
                ...state,
                userName: action.value
            }
        case "Email":
            return {
                ...state,
                email: action.value
            }
        case "Security_Level":
            return {
                ...state,
                securityLevel: action.value
            }

        case "oriRfp":
            //use action.index
            const currentGroups = state.oriRfpGroups
            currentGroups[action.index] = {
                ...currentGroups[action.index],
                [action.field]: action.value
            }
            return {
                ...state,
                oriRfpGroups: currentGroups
            }
        case AddUserAction.ORI_RFP_GROUP:
            const oriRfpGroups = state.oriRfpGroups
            oriRfpGroups[action.group.index][action.group.key].value = action.value
            console.log('debug addOriRfp editUser EditUserAction.ORI_RFP_GROUP return state: ', { ...state })
            return {
                ...state,
                oriRfpGroups

            }
        case AddUserAction.ADD_ORI_RFP_GROUP:
            console.log('debug addOriRfp editUser EditUserAction.ADD_ORI_RFP_GROUP return state: ', {
                ...state,
                oriRfpGroups: action.value

            })

            return {
                ...state,
                oriRfpGroups: action.value

            }
        case AddUserAction.REMOVE_ORI_RFP_GROUP:
            state.oriRfpGroups.splice(action.group.index, 1)
            return {
                ...state
            }
        case AddUserAction.REMOVE_EMPTY_ORI_RFP_GROUP:
            const emptyIndexes: Array<number> = []
            console.log('debug remove empty  state.oriRfpGroups: ', state.oriRfpGroups)
            state.oriRfpGroups.forEach((group, index) => {
                if (group.new) {
                    if (group.primaryOri && group.primaryOri.value.length < 1) {
                        emptyIndexes.push(index)
                    } else if (group.secondaryOri && group.secondaryOri.value.length < 1) {
                        emptyIndexes.push(index)
                    }
                }
            })
            if (emptyIndexes.length > 0) {
                state.oriRfpGroups.splice(emptyIndexes[0])
            }
            console.log('debug remove empty oris on cancel: ', { ...state })
            return {
                ...state
            }
    }
}