import React from "react"
import { Container } from "@mui/material"

export const InputWrapper: React.FC<any> = ({ children, customWidth, customMargin, customMarginTop, customMarginBottom }) => {

  const containerStyle = {
    margin: customMargin ? customMargin : 0,
    marginTop: customMarginTop ? customMarginTop : 0,
    marginBottom: customMarginBottom ? customMarginBottom : 0,
    display: "flex",
    justifyContent: "space-between",
    maxWidth: customWidth ? customWidth : "852px",
    height: "56px"
  }

  return (
    <Container disableGutters sx={containerStyle}>
      {children}
    </Container>
  )
}