/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

class ApiService {
    private baseUrl: string;
  
    constructor(baseUrl: string) {
      this.baseUrl = baseUrl;
    }
  
    async get(endpoint = ""): Promise<any> {
      const options: RequestInit = {
        method: "GET",
        credentials: "include"
      };
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, options);
        return {
          data: await response.json(),
          stauts: response.status
        }
      } catch (error) {
        throw new Error("An error occurred while making a GET request");
      }
    }
  
    async post(data: any, endpoint = ""): Promise<any> {
      const options: RequestInit ={
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, options);
        return response.json();
      } catch (error) {
        throw new Error("An error occurred while making a POST request");
      }
    }
  
    async put(data: any, endpoint = ""): Promise<any> {
      const options: RequestInit = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, options);
        return response.json();
      } catch (error) {
        throw new Error("An error occurred while making a PUT request");
      }
    }
  
    async delete(endpoint = "", data?: any): Promise<any> {
      const options: RequestInit = {
        credentials: "include",
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, options);
        return response.json();
      } catch (error) {
        throw new Error("An error occurred while making a DELETE request");
      }
    }
  
    async patch(data: any, endpoint = ""): Promise<any> {
      const options: RequestInit = {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, options);
        return response.json();
      } catch (error) {
        throw new Error("An error occurred while making a PATCH request");
      }
    }
  }
  
  export default ApiService;