export enum AddUserAction {
    LAST_NAME = 'LAST_NAME',
    FIRST_NAME = 'FIRST_NAME',
    USER_NAME = "USER_NAME",
    EMAIL = "EMAIL",
    STATUS = "STATUS",
    SECURITY_LEVEL = "SECURITY_LEVEL",
    ORI_RFP_GROUP = "ORI_RFP_GROUP",
    SET_CURRENT = "SET_CURRENT",
    ADD_ORI_RFP_GROUP = "ADD_ORI_RFP_GROUP",
    REMOVE_ORI_RFP_GROUP = "REMOVE_ORI_RFP_GROUP",
    REMOVE_EMPTY_ORI_RFP_GROUP = "REMOVE_EMPTY_ORI_RFP_GROUP",
    RESET = "RESET"
}