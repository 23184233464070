import { createTheme } from '@mui/material/styles';
import dark from './sass/_darkTheme.module.scss';
import { Typography } from './typography';

const darkTheme = createTheme({
    components: {
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    width: "100%"
                },
                icon: {
                    marginLeft: "auto"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.css-1y4j4gs-MuiPaper-root-MuiDialog-paper': {
                        backgroundColor: dark.primaryBg
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled .css-hsr7c6-MuiSvgIcon-root': {
                        color: '#585956'
                    }
                }
            },
            variants: [{
                props: { variant: 'text' },
                style: {
                    color: '#BC8FFF'
                }
            },
            {
                props: {variant: 'outlined'},
                style: {
                    color: '#BC8FFF',
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px'
                    }
                }
            },
            ]
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: dark.errorBorder
                    }
                },
                asterisk: {
                    '&.Mui-error': {
                        color: dark.errorBorder

                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: dark.errorBorder
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: dark.switchDeselected,

                },
                colorSecondary: {
                    "&.Mui-checked": {
                        // Controls checked color for the thumb
                        color: dark.switchTrackSelected,
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    backgroundColor: dark.switchTrackDeselected,
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        backgroundColor: dark.switchTrackSelected
                    }
                }
            }

        },
    },
    palette: {
        mode: 'dark',
        common: {
            black: '#1C1D27', // using this for applicant details background
            white: '#fff'
        },
        primary: {
            main: dark.primaryMain,
            contrastText: dark.primaryText
        },
        secondary: {
            main: dark.primaryButton, // using this for button color
            contrastText: dark.primaryText

        },
        background: {
            default: dark.primaryBg, // used for table, background of content
            paper: dark.primaryBgAlt, // use for body 
        },
        text: {
            primary: dark.primaryText,
            secondary: dark.secondaryText
        },
        action: {
            active: dark.secondaryText
        },
        // dark used for border
        success: {
            main: dark.successMain,
            dark: dark.sucessBorder,
            contrastText: dark.successText
        },
        error: {
            main: dark.errorMain,
            dark: dark.errorBorder,
            contrastText: dark.errorText
        },
        info: {
            main: dark.infoMain,
            dark: dark.infoBorder,
            contrastText: dark.infoText
        },
        warning: {
            main: dark.warningMain,
            dark: dark.warningBorder,
            contrastText: dark.warningText
        },
        divider: '#FFFFFF1F'
    },
    typography: Typography,
})

export default darkTheme;