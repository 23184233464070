import React, { useCallback, useEffect } from 'react'
import { Button, MenuItem, Menu, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';

enum OriRfpType {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY"
}

export const AddUserOriRfp: React.FC<any> = ({ formState, actionType }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const STATE = process.env.REACT_APP_CONFIG_STATE!

    useEffect(() => {
        console.log(`debug open addOriRfp handleClick open: `, open)
        console.log(`debug open addOriRfp handleClick anchorEl: `, anchorEl)

    }, [open])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log(`debug open addOriRfp handleClick event: `, event)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { oriRfpGroups } = formState[0]
    const dispatchFormValues = formState[1]

    const addOriRfp = useCallback((addPrimaryOrSecondaryOri: OriRfpType) => {
        const primaryOriRfp = {
            new: true,
            primaryOri: { field: "Primary Ori", value: "" },
            rfp: { field: "RFP", value: "",  required: true }
        }
        const secondaryOriRfp = {
            new: true,
            secondaryOri: { field: "Secondary Ori", value: "" },
            rfp: { field: "RFP", value: "" }
        }
        
        if (addPrimaryOrSecondaryOri === OriRfpType.PRIMARY) oriRfpGroups.push(primaryOriRfp)
        if (addPrimaryOrSecondaryOri === OriRfpType.SECONDARY) oriRfpGroups.push(secondaryOriRfp)
        
        dispatchFormValues({ type: actionType, value: oriRfpGroups });
            
        console.log('debug orirfpgroups', oriRfpGroups);
        console.log('debug formstate in adduserorirfp', formState);
        console.log('debug addOriRfp dispatchFormValues', dispatchFormValues);

    }, [oriRfpGroups])

    return (
        <Box sx={{ m: 1, display: 'flex', flexDirection: 'row', justifyContent: "space-evenly", mr: 0 }}>
            <Button onClick={handleClick} startIcon={<AddIcon />} sx={{ height: "22px", pr: 0 }}>ADD ORI/RFP GROUP</Button>
            <Menu
                slotProps={{ paper: { sx: { minWidth: "194px" } } }}
                id='selectPrimaryOrSecondary'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem sx={{ minWidth: "100%" }} onClick={() => {handleClose(); addOriRfp(OriRfpType.PRIMARY)}} value={'Primary'}>Primary ORI</MenuItem>
               {STATE === "me" && 
                <MenuItem sx={{ minWidth: "100%" }} onClick={() => {handleClose();  addOriRfp(OriRfpType.SECONDARY)}} value={'Secondary'}>Secondary ORI</MenuItem>
               } 
            </Menu>
        </Box>
    )
}
