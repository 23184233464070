export const BatchPrintingStyles = {
    tableHeadCellStyle: {
        backgroundColor: 'background.default',
        height: "56px",
        paddingRight: "0px",
        paddingLeft: "4px",
        paddingTop: "7px",
        paddingBottom: "7px",
        top: 0,
        zIndex: 2
    },
    printModalContent: {
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        maxHeight: "849px",
        padding: "0px 24px 20px 24px"
    },
    printModalTableContainer: {
        maxHeight: "930px",
        overflowY: "scroll",
        boxShadow: "none",
        borderRadius: 0
    },
    printModalTable: {
        width: "100%",
        paddingBottom: 1,
        backgroundColor: "background.default",
        borderRadius: 0
    },
    printModaltableSortLabel: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        fontSize: '0.875rem',
    },
    printModalCellLabel: {
        flex: 2, 
        fontFamily: "Montserrat", 
        fontWeight: 500, 
        fontSize: "14px", 
        lineHeight: "24px", 
        letterSpacing: "0.17px"
    },
    printModalTableBody: {
        overflowY: "scroll", 
        minWidth: "1760px", 
        backgroundColor: 'background.default'
    },
    printmodalTableCell: {
    paddingRight: "0px",
    paddingLeft: "4px",
    paddingTop: "7px",
    paddingBottom: "7px", 
    alignItems: "center"
}
}