/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useState } from "react";
import { urls } from "urls";
import { useParams } from "react-router-dom";
import { Button, Container, Divider, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApplicantDetailsFormProps } from '@propTypes/index';
import { useTableStore } from "state";
import { StatusHistory } from './StatusHistory'
import { AdjudicationDocs } from './AdjudicationDocs'
import { TableNames } from "customEnums/TableNames";

const inputContainer = {
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "16px",
  marginTop: "24px",
  marginBottom: "24px",
};

const sectionHeaderStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

export const ApplicantDetailsForm: React.FC<ApplicantDetailsFormProps> = ({
  usersPermissions,
  personalInfo,
  transactionalInfo,
  applicantFetchStatus,
  statusHistories,
  adjudicationDocuments
}) => {

  const [emails, setEmails] = useState<string[]>([]);
  const { registrationId } = useParams();
  const [ssnValues, setSsnValues] = useState({
    SSN: personalInfo["ssn"],
    showSSN: false
  });

  const applicantDetailFields = useTableStore(state => state.applicantDetailFields)

  const handleResendEmail = async (event: any) => {
    // const reporting_url = urls[env].REPORTING;
    const endpoint = `${process.env.NODE_ENV === "production" ? urls.REPORTING : ""
      }/adjudication/resend-email/${registrationId}`;

    const body = { emails: emails };

    const options: RequestInit = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
    };

    console.log("options", JSON.stringify(options));

    await fetch(endpoint, options)
      .then((response: Response) => {
        console.log("resend email decision success", response);
      })
      .catch((error: Error) => {
        console.error("resend email decision error", error);
      });

    alert(
      "Documents have been sent. If you have not received an email, please check the address provided"
    );
  };

  const handleClickShowSSN = () => {
    setSsnValues({ ...ssnValues, showSSN: !ssnValues.showSSN })
  }

  const [hide, setHide] = useState<any>([]);

  const hideSection = (key: any) => {
    if (hide.includes(key)) {
      setHide(hide.filter((section) => section !== key))
    } else {
      setHide([...hide, key])
    }
  }

  const isSectionVisible = (key) => hide.includes(key)

  return (
    <Container disableGutters maxWidth={false} sx={{ width: "100%", gap: 2, paddingTop: 3, paddingLeft: 3, paddingBottom: 4, paddingRight: 3, backgroundColor: 'common.black' }}>
      <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
        <Typography height="max-content" variant="h6" color='text.primary'>PERSONAL INFORMATION</Typography>
        <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('personal-info') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('personal-info')}>{!isSectionVisible('personal-info') ? 'HIDE' : 'SHOW'}</Button>
      </Container>
      <Container disableGutters maxWidth={false} sx={inputContainer} key='personal-info'>
        {!isSectionVisible('personal-info') &&
          applicantDetailFields["personal-info"]?.map((field: any) => {
            return (
              <TextField
                key={field.key}
                disabled={personalInfo[field.key] ? false : true}
                InputProps={field.key === "ssn" ? 
                  {disabled: true, endAdornment: usersPermissions['viewAllDetailsData'] ?
                      <InputAdornment position="end">
                        <Tooltip title={ssnValues.showSSN ? "Hide" : "Show"}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowSSN}
                            edge="end"
                          >
                            {ssnValues.showSSN ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment> : null
                  } : { disabled: true }}
                sx={{
                  flexBasis: field.key === "residenceAddress" ? "49.3%" : "24%",
                  flexGrow: 1,
                  maxWidth: field.key === "residenceAddress" ? "50%" : "24.5%"
                }}
                value={field.key === "ssn" ? ssnValues.showSSN ? personalInfo["ssn"] : "*********" : personalInfo[field.key]}
                InputLabelProps={{ shrink: true }}
                label={personalInfo[field.key] ? field.label : ""}
                placeholder={personalInfo[field.key] ? personalInfo[field.key] : field.placeHolder}
              />
            )
          })
        }
      </Container>
      <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
      <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
        <Typography variant="h6" color='text.primary'  >TRANSACTIONAL INFORMATION</Typography>
        <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('transaction-info') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('transaction-info')}>{!isSectionVisible('transaction-info') ? 'HIDE' : 'SHOW'}</Button>
      </Container>
      <Container disableGutters maxWidth={false} sx={inputContainer} key='personal-info'>
        {!isSectionVisible('transaction-info') &&
          applicantDetailFields["transaction-info"]?.map((field: any) => {
            return (
              <TextField
                key={field.key}
                disabled={transactionalInfo[field.key] ? false : true}
                InputProps={{ disabled: true }}
                sx={{
                  flexBasis: "24%",
                  flexGrow: 1,
                  minWidth: '24%',
                  
                  maxWidth: "24%"
                }}
                value={transactionalInfo[field.key]}
                InputLabelProps={{ shrink: true }}
                label={transactionalInfo[field.key] ? field.label : ""}
                placeholder={transactionalInfo[field.key] ? transactionalInfo[field.key] : field.placeHolder}
              />
            )
          })
        }
      </Container>
      <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />

      <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
        <Typography variant="h6" color='text.primary'>STATUS HISTORY</Typography>
        <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('status-info') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('status-info')}>{!isSectionVisible('status-info') ? 'HIDE' : 'SHOW'}</Button>
      </Container>
      {!isSectionVisible('status-info') &&
        <StatusHistory
          totalRecords={statusHistories?.length}
          statusHistories={statusHistories?.length ? statusHistories : [{ status: "NO DATA" }]}
          name={TableNames.STATUS_HISTORY}
        />
      }
      {
        <>
          <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
          <Container disableGutters maxWidth={false} sx={sectionHeaderStyle}>
            <Typography variant="h6" color='text.primary'>ADJUDICATION DOCUMENTS</Typography>
            <Button size="small" variant="text" color="primary" endIcon={!isSectionVisible('adjudication-doc') ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} onClick={() => hideSection('adjudication-doc')}>{!isSectionVisible('adjudication-doc') ? 'HIDE' : 'SHOW'}</Button>
          </Container>
          {!isSectionVisible('adjudication-doc') &&
            <AdjudicationDocs
              name={TableNames.ADJUDICATION_DOCS}
              totalRecords={adjudicationDocuments?.length}
              adjudicationDocuments={adjudicationDocuments?.length ? adjudicationDocuments : [{ addedDate: "NO DATA" }]}
            />
          }
        </>
      }
    </Container>
  );
};
