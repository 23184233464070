/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import CopyrightIcon from '@mui/icons-material/Copyright';
import { Typography, Grid } from '@mui/material';

export const FooterLayout = () => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' direction='column' pb='1rem' >
                <Typography variant='body2' color='text.primary'>
                    IDEMIA <CopyrightIcon sx={{ width: '1rem', mb: '-0.44rem' }} />2023 ALL RIGHTS RESERVED
                </Typography>
            </Grid>
        </>
    )
};