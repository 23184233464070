import { AlertColor } from '../../customEnums/AlertColor';

export const AlertStyles = {
    success: {
        backgroundColor: 'success.main',
        mb: '1rem',
        borderColor: 'success.dark',
        border: '1px solid',
        '.MuiAlert-icon': {
            color: 'success.contrastText'
        }
    },
    info: {
        backgroundColor: 'info.main',
        mb: '1rem',
        borderColor: 'info.dark',
        border: '1px solid',
        '.MuiAlert-icon': {
            color: 'info.contrastText'
        }
    },
    error: {
        backgroundColor: 'error.main',
        mb: '1rem',
        borderColor: 'error.dark',
        border: '1px solid',
        '.MuiAlert-icon': {
            color: 'error.contrastText'
        }
    },
    warning: {
        backgroundColor: 'warning.main',
        mb: '1rem',
        borderColor: 'warning.dark',
        border: '1px solid',
        '.MuiAlert-icon': {
            color: 'warning.contrastText'
        },
        '& .MuiAlert-action': {
            color:  'warning.contrastText'
          }
    }
}

export const getBackgroundColor = (severity) => {
    switch (severity) {
        case AlertColor.INFO:
            return 'info.main';
        case AlertColor.SUCCESS:
            return 'success.main';
        case AlertColor.ERROR:
            return 'error.main';
        case AlertColor.WARNING:
            return 'warning.main';
        default:
            return 'inherit';
    }
};

export const getBorderColor = (severity) => {
    switch (severity) {
        case AlertColor.INFO:
            return 'info.dark';
        case AlertColor.SUCCESS:
            return 'success.dark';
        case AlertColor.ERROR:
            return 'error.dark';
        default:
            return 'inherit';
    }
};

export const getIconColor = (severity) => {
    switch (severity) {
        case AlertColor.INFO:
            return 'info.contrastText';
        case AlertColor.SUCCESS:
            return 'success.contrastText';
        case AlertColor.ERROR:
            return 'error.contrastText';
        case AlertColor.WARNING:
            return 'warning.contrastText';
        default:
            return 'inherit';
    }
};