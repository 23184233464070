export enum SavedSearchActionTypes {
    DELETE_SEARCH = 'DELETE_SEARCH',
    LOAD_SEARCHES = 'LOAD_SEARCHES',
    ADD_SAVED_SEARCH = 'ADD_SAVED_SEARCH',
    UNLOAD_SEARCHES = "UNLOAD_SEARCHES"
  }
  
  export enum AdvancedSearchAction {
    LAST_NAME = 'LAST_NAME',
    FIRST_NAME = 'FIRST_NAME',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
    LOWER_DATE = 'LOWER_DATE',
    UPPER_DATE = 'UPPER_DATE',
    RFP = 'RFP',
    DAYS = 'DAYS',
    RESET = 'RESET',
    TCN = 'TCN'
  }
