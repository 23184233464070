export const AppLayoutStyle = {
    loginBackground: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        // flexDirection: 'column',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        gap: 'var(--3, 1.5rem)',
        borderRadius: 0,
    },
    loginContainer: {
        width: '34.5rem',
        margin: 'auto',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    protectedLayoutContainer: {
        height: "100vh", 
        display: "flex",  
        flexDirection:  "column"
    },
    appContainerStyle: {
        display: 'flex', 
        flexDirection: "row", 
    },
    navLinksContainerStyle: {
        backgroundColor: "background.paper", 
        borderRadius: 0,
        height: "100vh", 
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRight: '0.1px solid',
        borderRightColor: 'divider'
    }
}