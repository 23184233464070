import { urls } from "../urls";
import { useEffect, useState } from "react";
import {
    generateRBScubscriptionQueryParams, getCurrentEnv,
    transformMaintenanceData, transformPrenotificationsData,
    transformRapBackData, transformValidationsData
} from "../utils";
import { QueryRequestBody } from "interfaces/common";
import { PageNames } from "interfaces/config";
import { useTableStore } from "../state";
import { AlertColor } from "customEnums/AlertColor";
import { useRapbackMaintenanceStore } from '../pages/RapbackSubscriptions/RapbackMaintenance/state'
import { TableNames } from "customEnums/TableNames";
import { Auth } from "aws-amplify";

export const getCount = (obj: object) => {
    return Object.values(obj).filter(value => value === true).length
}

export const useRapbackSubscription = (
    pageName: string,
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    userORIs: any,
    userSecondaryORIs: any,
    userRole: any,
    searchParams: any,
    auditSearch: boolean,
    tableName: string = "",
    queryParams: { value: string, name: string }[] = []
) => {

    const STATE = getCurrentEnv
    const queryUrl = urls.QUERY
    const rapbackUrl = urls.RAPBACK

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const [queryDataRequest, setQueryDataRequest] = useState<any>()
    const [queryCountRequest, setQueryCountRequest] = useState<any>()

    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const setOutStandingRapback = useTableStore((state) => state.setOutStandingRapback)
    const outstandingRapback = useTableStore((state) => state.outstandingRapback)
    const setNeedsAttention = useTableStore(state => state.setNeedsAttention);
    const fullName = useRapbackMaintenanceStore(state => state.fullName)
    const receivedResponse = useTableStore((state) => state.receivedResponse)
    const setReceivedResponse = useTableStore((state) => state.setReceivedResponse)
    const columnState = useTableStore(state => state.columnState)
    const tableConfig = useTableStore(state => state.tableConfig)
    //const pagination = useTableStore(state => state.pagination)
    //const setPagination = useTableStore(state >)

    console.log(`debug updated config useRapbackSubscription: `, { columnState, tableConfig })

    const statusFilters = columnState.page["rapback-maintenance"]!.filters?.find(filter => filter.filterLabel === "Filter Status")
    const timeFilters = columnState.page["rapback-validations"]!.filters?.find(filter => filter.filterLabel === "Time Remaining")

    const fetchMaintenance = async (queryDataRequest: any, queryCountRequest: any) => {

        const url: string = `${process.env.NODE_ENV === "production" ? queryUrl : ""}/query`;
        try {
            setLoading(true)

            const data = await (await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryDataRequest)
            })).json();

            const { results } = data['queriesResults'][0];

            console.log('debug rapback badge obj rapbacksubscription results: ', results)

            let updatedOutstandingRapback = { ...outstandingRapback }
            results.forEach((result: any) => {
                if (result.needsConfirm === "true") {
                    console.log('debug rapback badge obj needs confirm: ', result)
                    updatedOutstandingRapback = { ...updatedOutstandingRapback, preNotification: true };
                    setNeedsAttention(true)
                }
                if (result.daysToExpire < 60 && result.daysToExpire !== null) {
                    updatedOutstandingRapback = { ...updatedOutstandingRapback, validations: true };
                    setNeedsAttention(true)
                }
            })

            console.log('debug rapback badge obj setting outstanding rapback:  ', updatedOutstandingRapback)
            setOutStandingRapback(updatedOutstandingRapback);

            const countData = await (await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryCountRequest)
            })).json();

            console.log('error countData: ', countData)
            if (!countData['queriesResults'][0].errorMessage) {
                setTotalRecords(countData['queriesResults'][0].results[0]['count'])
            }

            const transformedData = results.map((obj: any, index: number) => {
                console.log(`debug validations  pageName ${pageName}`)

                switch (pageName) {
                    case PageNames.RAPBACK_MAINTENANCE:
                        return transformMaintenanceData(obj, index)
                    case PageNames.RAPBACK_PRENOTIFICATIONS:
                        return transformPrenotificationsData(obj, index)
                    case PageNames.RAPBACK_VALIDATIONS:
                        let transformed = transformValidationsData(obj, index)
                        return transformed
                }
            });

            setMappedData(transformedData);
            setLoading(false);

        } catch (err: any) {
            console.error('error querying rapback subs: ', err)
            setError(err);
            setLoading(false);
        }
    }

    const handleConfirmPrenotification = async (subscriptionId: string) => {
        console.log(`handleConfirmPrenotification ran`)
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/confirm/${subscriptionId}?username=${username}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A pre-notification acceptance request has been submitted for ${fullName}. View pending details on the Rapback Maintenance screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to accept ${fullName}'s subscription`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleDenyPrenotification = async (subscriptionId: string) => {
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/deny/${subscriptionId}?deletion_source=prenotify&username=${username}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A pre-notification rejection request has been submitted for ${fullName}. View pending detials on the Rapback Maintenance Screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to decline ${fullName}'s subscription`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleUpdateSubscription = async (subscriptionId: string, expirationDate: string, dob: string) => {
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/update/${subscriptionId}?username=${username}`;

        const body = {
            expiration_date: expirationDate,
            dob,
            rapback_id: subscriptionId
        }

        console.log('handleUpdateSub body: ', body)
        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })
            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Successfully Submitted");
                setAlertMessage(`Edit Requests will be reviewed`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)

            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Error`)
                setAlertMessage(`Your attempt to edit has failed, please try again or contact support`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleUnsubscribe = async (subscriptionId: string) => {
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/unsubscribe/${subscriptionId}?deletion_source=${pageName === "rapback-maintenance" ? "maintenance" : "validation"}&username=${username}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            console.log('unsubscribe response: ', resp)
            if (resp.ok) {
                if (pageName === "rapback-maintenance") {
                    fetchMaintenance(queryDataRequest, queryCountRequest)
                    setOpenNotification(true);
                    setAlertTitle("Request Successfully Submitted");
                    setAlertMessage(`Unsubscribe request will be reviewed. If approved, ${fullName} will be unsubscribed from receiving Rapbacks.`)
                    setSeverity(AlertColor.SUCCESS)
                    setReceivedResponse(true)
                }
                if (pageName === "rapback-validations") {
                    setAlertTitle("Request Submitted");
                    setAlertMessage(`A rapback subscription cancellation request has been submitted for ${fullName}. View pending detials on the Rapback Maintenance Screen.`)
                    setSeverity(AlertColor.SUCCESS)
                    setOpenNotification(true)
                    setReceivedResponse(true)

                }
                return resp.ok
            } else {
                if (pageName === "rapback-maintenance") {
                    setOpenNotification(true);
                    setSeverity(AlertColor.ERROR)
                    setAlertTitle(`Request Error`)
                    setAlertMessage(`Your attempt to unsubscribe ${fullName} has failed, please try again or contact support.`)
                    setReceivedResponse(true)
                }
                if (pageName === "rapback-validations") {
                    setOpenNotification(true);
                    setSeverity(AlertColor.ERROR)
                    setAlertTitle(`Request Failed`)
                    setAlertMessage(`Please try again to cancel ${fullName}'s subscription`)
                    setReceivedResponse(true)
                }
                return false
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleRenew = async (subscriptionId: string) => {
        console.log(`Validations handleRenew hook subscriptionId: ${subscriptionId}`)
        const { username } = await Auth.currentAuthenticatedUser();

        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/renew/${subscriptionId}?username=${username}`;

        console.log(`Validations handleRenew hook url: ${url}`)

        try {
            console.log(`Validations handleRenew hook fetching....`)

            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(queryDataRequest, queryCountRequest)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A rapback subscription renewal request has been submitted for ${fullName}. View pending details on the Rapback Maintenance screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to renew ${fullName}'s subscription`)
            }
            return resp.ok
        } catch (err: any) {
            setError(err)
        }
    }

    useEffect(() => {



        console.log('recevied response or no', receivedResponse)

        const controller = new AbortController();

        //update column names for dates
        const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate" || param.column === "dob")

        let filters = columnState.page[pageName][tableName]?.filters
        // let pageTableHeaderCellsLength = pageConfig?.tableHeaderCells.length

        const filterLabels: any[] = []
        let filterNumbers: any[] = []
        if (filters) {
            filters.forEach(filter => {
                let filterValueKeys = Object.keys(filter.filterValues);
                filterValueKeys.forEach(key => {
                    const { boolNumber, selected, label } = filter.filterValues[key];
                    if (pageName === "rapback-validations") {
                        filterNumbers.push(boolNumber);
                    }
                    if (selected) {
                        console.log('filter.filterValues[key]: ', filter.filterValues[key]);
                        if (pageName === "rapback-maintenance") {
                            filterLabels.push(label);
                        } else if (pageName === "rapback-validations") {
                            filterLabels.push(boolNumber);
                        }
                    }
                });
            });
        }

        console.log('debug rapback badge obj 394 requerying rapback table: ', {
            filters,
            filterLabels,
            STATE,
            userRole,
            userORIs,
            pageSize,
            currentPage,
            order,
            orderBy,
            searchParams,
            columnState,
            statusFilters,
            timeFilters,
            tableName,
            runQuery: userORIs && userORIs.length && userRole && filterLabels.length > 0
            //filtersLength: columnState.page[pageName][tableName]!.filters?.length,
        })

        let runQuery
        if (pageName === "rapback-maintenance" || pageName === "rapback-validations") {
            runQuery = userORIs && userORIs.length && userRole && filterLabels.length > 0
        } else {
            runQuery = userORIs && userORIs.length && userRole
        }

        if (runQuery) {
            let rbSubQueryParams
            if (queryParams.length > 0) {
                rbSubQueryParams = queryParams
            } else {
                rbSubQueryParams = generateRBScubscriptionQueryParams(pageName, userORIs, userSecondaryORIs, searchParams, filterLabels, filterNumbers, orderBy, order, param)
            }

            console.log('rapbacksub query params: ', queryParams)
            console.log('debug rapback badge obj pagination: ', { pageSize, currentPage, offset: `(${currentPage} - 1) * ${pageSize}: ${(currentPage - 1) * pageSize}` })
            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...rbSubQueryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTable"
                    }
                ]
            }

            setQueryDataRequest(queryDataRequest)

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...rbSubQueryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTableCount"
                    }
                ]
            }

            setQueryCountRequest(queryCountRequest)

            console.log('debug rapback badge obj 394 requerying rapback table: ', {
                STATE,
                userRole,
                userORIs,
                pageSize,
                currentPage,
                order,
                orderBy,
                searchParams,
                columnState,
                statusFilters,
                timeFilters,
                tableName
                //filtersLength: columnState.page[pageName][tableName]!.filters?.length,
            })

            if (STATE === "ga") {
                console.log('debug rb maintenance calling fetching maintence data ', STATE === 'ga')
                fetchMaintenance(queryDataRequest, queryCountRequest)
            }

        }

        console.log('debug searchParams: ', searchParams)
    }, [STATE,
        userRole,
        userORIs?.length,
        pageSize,
        currentPage,
        order,
        orderBy,
        searchParams,
        searchParams?.length,
        columnState,
        columnState.page[pageName][tableName]?.filters?.length,
        statusFilters,
        timeFilters,
        tableName
    ]

    )

    return { data: mappedData, error, loading, totalRecords, fetchMaintenance, handleConfirmPrenotification, handleDenyPrenotification, handleUpdateSubscription, handleUnsubscribe, handleRenew };
}


