/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  Divider,
  Typography,
  Button,
  Alert,
  AlertTitle,
  MenuItem,
  TextField,
  CircularProgress,
  Grid,
  Container,
  Table
} from "@mui/material";
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import UpArrow from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  ApplicantDetailsActionTypes,
  useApplicantDetailsContext,
} from "../../ApplicantDetailsContext/ApplicantDetailsProvider";
import CustomEditor from "./CustomEditor/CustomEditor";
import useNotes from "./CustomEditor/useNotes";
import { useExpectedResponse } from "hooks/useExpectedResponses";
import { useAudit } from "hooks/useAudit";
import { CustomTable } from "common/CustomTable/CustomTable";
import { v4 as uuid } from 'uuid'
import { ResultsProps } from "interfaces/pages/components";
import { useTableStore, useUserStore } from "../../../../../state";
import { generateTableConfig } from "utils";
import { extractPageName } from "common/CustomTable/helpers";
import { TableNames } from "customEnums/TableNames";
import { OutgoingTransmissionTable } from "./OutgoingTransmissionTable"
import { IncomingResponseTable } from "./IncomingResponseTable"

const Results: React.FC<ResultsProps> = ({
  userRole,
  results,
  rapSheets,
  transactionalInfo,
  resultsResponses,
  tabValue,
  setEditRedactedMode,
  editRedactedMode,
  setTextChanges,
  textChanges,
  // usersPermissions
}) => {

  // console.log("debugging usersPermissions: ", usersPermissions);
  console.log('resultsResponses12312312', resultsResponses)

  // useEffect(() => {
  //   console.log(
  //     "debugging useEffect usersPermissions updated: ",
  //     usersPermissions
  //   );
  // }, [usersPermissions]);

  console.log("update decision results: ", results);

  let originalText: string = "";
  if (rapSheets && rapSheets.length > 0) {
    rapSheets.forEach((str: string, index: number) =>
      index === 0
        ? (originalText = str)
        : (originalText =
          `
      ${originalText +
          `\n \n <strong>End of sheet ${index}</strong> \n ----------------------------------------------------------------------------------- \n `
          }` + `${str}`)
    );
  }

  const multiTablePagination = useTableStore((state) => state.multiTablePagination)
  const { notesJSON, fetchStatus } = useNotes("redact");
  const [isVisible, setIsVisible] = useState(true);
  const [rapSheetVisible, setRapSheetVisible] = useState(true);
  const [view, setView] = useState('Original');
  const [redactedTextCreation, setRedactionTextCreation] = useState<string>('');
  const [createdRedaction, setCreatedRedaction] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<Object>>(results);
  const [manualAdjudication, setManualAdjudication] = useState<boolean>(false);
  const [resultRespData, setResultRespData] = useState<Array<Object>>(resultsResponses)
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [incomingPageSize, setIncomingPageSize] = useState(5);
  const [currentIncomingPage, setCurrentIncomingPage] = useState(0);
  //const [visibleRows, setVisibleRows] = React.useState(tableData);
  const [visibleRowsIncoming, setVisibleRowsIncoming] = React.useState(resultRespData);
  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)
  const setTableConfig = useTableStore(state => state.setTableConfig)
  const tableConfig = useTableStore(state => state.tableConfig)
  const { config, tableHeaderCells } = tableConfig
  const userConfig = useUserStore(state => state.userConfig)

  const { usersPermissions } = userConfig;

  useEffect(() => {
    console.log('Add redaction has been activated:', editRedactedMode)
  }, [editRedactedMode])

  const decodeRedactedText = (text: any) => {
    let doc = new DOMParser().parseFromString(text, 'text/html');
    let decodedText: any = doc.documentElement.textContent;
    const sanitizedInput =
      decodedText
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&#x([0-9A-Fa-f]+);/g, (match: any, hex: any) => String.fromCharCode(parseInt(hex, 16)));
    return sanitizedInput;
  }

  const redactedText = decodeRedactedText(notesJSON?.[0]?.body)

  const changeView = (e: any) => {
    setView(e.target.value)
  }

  const toggleDataVisibility = () => {
    setRapSheetVisible(!rapSheetVisible);
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  useEffect(() => {
    if (resultsResponses.length !== 0) {
      console.log('CustomTableFooter onRowsPerPageChange resultResponses non sorted: ', resultsResponses)
      const sortResultRespData = resultsResponses.sort((a: any, b: any) => {
        if (a.type1Field7.toLowerCase() === 'state') return -1;
        if (b.type1Field7.toLowerCase() === 'state') return 1;
        return 0
      })
      console.log('CustomTableFooter onRowsPerPageChange resultResponses sorted: ', sortResultRespData)
      setResultRespData(sortResultRespData)
    }
  }, [resultsResponses])

  useEffect(() => {
    const sortTableData = results?.sort((a: any, b: any) => {
      if (a.agency.toLowerCase() === 'state') return -1;
      if (b.agency.toLowerCase() === 'state') return 1;
      return 0
    })
    setTableData(sortTableData);
    if (
      results?.some(
        (statuses: { status: string }) =>
          statuses.status === "Manual Adjudication"
      )
    )
      setManualAdjudication(true);
  }, [results, manualAdjudication, userRole]);

  type MyParams = {
    registrationId: string;
  };
  const { registrationId } = useParams<keyof MyParams>() as unknown as MyParams;
  const { applicantDetailsDispatch } = useApplicantDetailsContext();
  const { addAuditEvent } = useAudit();
  const { expectedResponse } = useExpectedResponse(registrationId);

  const isLoading = fetchStatus === "loading" || fetchStatus === "idle";

  const notesLoadingContainer = {
    display: 'flex',
    padding: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
    backgroundColor: 'common.black'
  }

  if (isLoading) {
    return (
      <Grid sx={notesLoadingContainer}>
        <CircularProgress />
      </Grid>
    );
  }

  const updateDecision = async (e: any) => {
    console.log("update decision ran or e: ", e);
    applicantDetailsDispatch({
      type: ApplicantDetailsActionTypes.UPDATE_DECISION,
      payload: { decision: e },
    });
    await addAuditEvent(
      `${ApplicantDetailsActionTypes.UPDATE_DECISION}`,
      `Decision: ${e}`,
      new Date(),
      undefined
    );
  };

  const handleResponseType = (type: string) => {
    if (type.toLowerCase() === 'n') {
      return 'No Hit'
    }
    if (type.toLowerCase() === 'y') {
      return 'Hit'
    }
    if (type.toLowerCase() === 'errt') {
      return 'ERRT-Error'
    } else {
      return type
    }
  }

  let newResultRespData = resultRespData.map((v: any) => {
    console.log('CustomTableFooter onRowsPerPageChange resultResponses map v: ', v)
    return {
      ...v,
      uuid: uuid(),
      type2Field59: handleResponseType(v.type2Field59)
    }
  }
  )

  const canViewRedactedResult = usersPermissions["viewRedactedResults"];
  const canEditRedactedREsults = usersPermissions["editRedactedResults"];

  const resultsBodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '1rem',
    padding: '1.5rem',
    backgroundColor: 'common.black',
    height: 'auto'
  }
  const infoAlertStyle = {
    backgroundColor: 'info.main',
    border: '1px solid',
    borderColor: 'info.dark',
    width: '100%',
    '.MuiAlert-icon': {
      color: 'info.contrastText'
    }
  }
  const successAlertStyle = {
    width: '100%',
    border: '1px solid',
    borderColor: 'success.dark',
    backgroundColor: 'success.main',
    '.MuiAlert-icon': {
      color: 'success.contrastText'
    }
  }

  const allResponsesReceived = resultRespData.length === expectedResponse;



  return (
    <>
      <Container sx={resultsBodyStyle} disableGutters={true} maxWidth={false} >

        <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant='h6' color='text.primary'>RESULTS</Typography>
          <Button size='small' endIcon={isVisible ? <DownArrow style={{ color: '#8A72FF' }} /> : <UpArrow style={{ color: '#8A72FF' }} />}
            disableRipple onClick={toggleVisibility} sx={{ padding: '4px 5px' }}>
            {isVisible ? 'HIDE' : 'SHOW'}
          </Button>
        </Grid>
        {isVisible ? (
          <>
            <Typography variant='body1' color='text.primary' >
              Review all outgoing transmissions and incoming responses.
              {(usersPermissions["createNewRedactedResults"] || usersPermissions["editRedactedResults"]) &&
                ' You may redact response data when all AFIS responses are received.'
              }

            </Typography>
            <Typography variant='subtitle2' color='text.primary'>OUTGOING TRANSMISSION</Typography>
            <OutgoingTransmissionTable
              totalRecords={tableData.length}
              name={TableNames.OUTGOING_TRANSMISSION}
              tableData={tableData} />

            <Typography variant='subtitle2' color='text.primary' sx={{ marginTop: '1rem' }}>INCOMING RESPONSE</Typography>

            {expectedResponse &&
              <Alert
                severity={!allResponsesReceived ? "info" : 'success'}
                sx={allResponsesReceived ? successAlertStyle : infoAlertStyle}
              >
                <AlertTitle sx={{ color: allResponsesReceived ? 'success.contrastText' : 'info.contrastText' }}>
                  {allResponsesReceived ? 'All responses received.' : 'Responses pending.'}
                </AlertTitle>
                <Typography color={allResponsesReceived ? 'success.contrastText' : 'info.contrastText'} variant='body2'>
                  {resultRespData.length} of {expectedResponse} responses received. {!allResponsesReceived && usersPermissions["createNewRedactedResults"] && 'Please wait on the remaining results to redact response information.'}
                </Typography>
              </Alert>}

            <IncomingResponseTable tableData={newResultRespData?.length ? newResultRespData : [{ type2Field59: "NO DATA" }]} totalRecords={newResultRespData.length} name={TableNames.INCOMING_RESPONSE} />


          </>
        ) : null}

        {usersPermissions["viewOriginalResults"] &&
          <>
            <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
            <Grid sx={{ height: 'auto', width: '100%', p: 0 }} >
              <Grid display='flex' justifyContent='space-between' width='100%' alignItems='center' >
                <Typography variant='h6' color='text.primary' >RESPONSE DATA</Typography>
                <Grid width='18rem' justifyContent={usersPermissions["viewRedactedResults"] ? 'space-between' : 'flex-end'} display='flex' gap='1.5rem'>
                  {usersPermissions["viewRedactedResults"] &&
                    <TextField
                      sx={{ width: '14rem' }}
                      select
                      label="Select View"
                      defaultValue={'Original'}
                      value={view}
                      disabled={(usersPermissions["viewRedactedResults"] && (notesJSON.length !== 0 || createdRedaction)) && !editRedactedMode ? false : true}
                      SelectProps={{
                        sx: {
                          fontFamily: 'Montserrat',
                          '&.MuiMenu-paper': {
                            backgroundColor: 'red'
                          }
                        },
                      }}
                      onChange={changeView}
                    >
                      <MenuItem value={'Original'}>Original</MenuItem>
                      <MenuItem value={'Redacted'}>Redacted</MenuItem>
                      <MenuItem value={'Original & Redacted'}>Original & Redacted</MenuItem>

                    </TextField>
                  }
                  <Grid item display='flex' justifyContent='center' alignItems='center'>
                    <Button size='small' endIcon={isVisible ? <DownArrow style={{ color: '#8A72FF' }} /> : <UpArrow style={{ color: '#8A72FF' }} />}
                      disableRipple onClick={toggleDataVisibility} sx={{ padding: '4px 5px', height: '30.75px', alignItems: 'center' }}>
                      {rapSheetVisible ? 'HIDE' : 'SHOW'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {rapSheetVisible ? (
                <>
                  {usersPermissions["viewOriginalResults"] && view === 'Original' ?
                    <CustomEditor
                      canPrint={usersPermissions["printOriginalResults"]}
                      setEditRedactedMode={setEditRedactedMode}
                      isEditable={false}
                      originalText={originalText}
                      tcn={transactionalInfo.transactionNumber}
                      isVisible={usersPermissions["viewOriginalResults"]}
                      redactedJSON={notesJSON}
                      view={view}
                      setView={setView}
                      canCreateNewRedaction={usersPermissions["createNewRedactedResults"]}
                      redactedTextCreation={redactedTextCreation}
                      createdRedaction={createdRedaction}
                      allResponsesRecieved={resultRespData.length === expectedResponse}
                      textChanges={textChanges}
                      registrationId={registrationId}
                    />
                    : null}
                  {canViewRedactedResult && view === 'Redacted' ?
                    <CustomEditor
                      isEditable={false}
                      originalText={redactedTextCreation ? redactedTextCreation : redactedText}
                      redactedText={redactedText}
                      redactedJSON={notesJSON}
                      tcn={transactionalInfo.transactionNumber}
                      isVisible={canViewRedactedResult}
                      view={view}
                      canPrint={usersPermissions["printRedactedResults"]}
                      redactedTextCreation={redactedTextCreation}
                      createdRedaction={createdRedaction}
                      setView={setView}
                      textChanges={textChanges}
                      registrationId={registrationId}
                    />
                    : null}
                  {canViewRedactedResult && usersPermissions["viewOriginalResults"] && view === 'Original & Redacted' ?
                    <CustomEditor
                      isEditable={canEditRedactedREsults && editRedactedMode}
                      setEditRedactedMode={setEditRedactedMode}
                      editRedactedMode={editRedactedMode}
                      viewOriginal={originalText}
                      originalText={redactedTextCreation ? redactedTextCreation : redactedText !== 'undefined' ? redactedText : originalText}
                      redactedJSON={notesJSON}
                      tcn={transactionalInfo.transactionNumber}
                      isVisible={usersPermissions["viewRedactedResults"]}
                      view={view}
                      canPrint={usersPermissions["printRedactedResults"]}
                      setView={setView}
                      setRedactionTextCreation={setRedactionTextCreation}
                      setCreatedRedaction={setCreatedRedaction}
                      createdRedaction={createdRedaction}
                      setTextChanges={setTextChanges}
                      textChanges={textChanges}
                      tabValue={tabValue}
                      redactedText={redactedText}
                      registrationId={registrationId}
                    />
                    : null}
                </>
              )
                : null}
            </Grid>
          </>
        }
      </Container>
    </>
  );
};
export default Results;
