import { create } from "zustand"

interface AgencyState {
    newAgencyForm: {
        ori: boolean,
        agencyName: boolean,
        reasonFingerPrinted: boolean,
        distributionMethod: boolean,
        agencyAddress: boolean,
        agencyCity: boolean,
        agencyState: boolean,
        agencyZipcode: boolean
    }
    setNewAgencyForm: (newAgencyForm: object) => void
    sortOrder: string
    setSortOrder: (sortOrder: string) => void
    orderBy: string
    setOrderBy: (orderBy: string) => void
    tableFilters: object
    setTableFilters: (tableFilters: object) => void
    openModal: boolean
    setOpenModal: (openModal: boolean) => void
    newAgencyInfo: object
    setNewAgencyInfo: (newAgencyInfo: object) => void
    openAgencyEdit: boolean
    setOpenAgencyEdit: (openAgencyEdit: boolean) => void
    selectedRowText: object
    setSelectedRowText: (selectedRowText: object) => void
    updatedAgency: boolean
    setUpdatedAgency: (updatedAgency: boolean) => void
    addedAgency: boolean
    setAddedAgency: (addedAgency: boolean) => void
    error: string
    setError: (error: string) => void
    oriExists: boolean
    setOriExists: (oriExists: boolean) => void
    rfpExists: boolean
    setRfpExists: (rfpExists: boolean) => void
    canAddAgency: boolean
    setCanAddAgency: (canAddAgency: boolean) => void
    fieldError: boolean
    setFieldError: (fieldError: boolean) => void
    touchedFields: object
    setTouchFields: (touchedFields: object) => void
    alreadyExistError: string
    setAlreadyExistError: (alreadyExistError: string) => void
    edited: boolean
    setEdited: (edited: boolean) => void
    searched: boolean
    setSearched: (searched: boolean) => void
    value: any
    setValue: (value: any) => void
    previousAgencyData: object
    setPreviousAgencyData: (previousAgencyData: object) => void
    data: any
    setData: (data: any) => void
    dataIndex: string[]
    setDataIndex: (fieldNames: string[]) => void
}

export const useAgencyManagementStore = create<AgencyState>((set, get) => ({
    newAgencyForm: {
        ori: false,
        agencyName: false,
        reasonFingerPrinted: false,
        distributionMethod: false,
        agencyAddress: false,
        agencyCity: false,
        agencyState: false,
        agencyZipcode: false
    },
    setNewAgencyForm: (newAgencyForm: any) => set({newAgencyForm}),
    data: [],
    setData: (data: any) => set({data}),
    dataIndex: [],
    setDataIndex: (dataIndex: string[]) => set({dataIndex}),
    sortOrder: "",
    setSortOrder: (sortOrder: string) => set({sortOrder}),
    orderBy: "",
    setOrderBy: (orderBy: string) => set({orderBy}),
    tableFilters: {},
    setTableFilters: (tableFilters: object) => set({tableFilters}),
    openModal: false,
    setOpenModal: (openModal: boolean) => set({openModal}),
    newAgencyInfo: {},
    setNewAgencyInfo: (newAgencyInfo: object) => set({newAgencyInfo}),
    openAgencyEdit: false,
    setOpenAgencyEdit: (openAgencyEdit: boolean) => set({openAgencyEdit}),
    selectedRowText: {},
    setSelectedRowText: (selectedRowText: object) => set({selectedRowText}),
    updatedAgency: false,
    setUpdatedAgency: (updatedAgency: boolean) => set({updatedAgency}),
    addedAgency: false,
    setAddedAgency: (addedAgency: boolean) => set({addedAgency}),
    error: "",
    setError: (error: string) => set({error}),
    oriExists: false,
    setOriExists: (oriExists: boolean) => set({oriExists}),
    rfpExists: false,
    setRfpExists: (rfpExists: boolean) => set({rfpExists}),
    canAddAgency: false,
    setCanAddAgency: (canAddAgency: boolean) => set({canAddAgency}),
    fieldError: false,
    setFieldError: (fieldError: boolean) => set({fieldError}),
    touchedFields: {},
    setTouchFields: (touchedFields: object) => set({touchedFields}),
    alreadyExistError: "",
    setAlreadyExistError: (alreadyExistError: string) => set({alreadyExistError}),
    edited: false,
    setEdited: (edited: boolean) => set({edited}),
    searched: false,
    setSearched: (searched: boolean) => set({searched}),
    value: [],
    setValue: (value: any) => set({value}),
    previousAgencyData: {},
    setPreviousAgencyData: (previousAgencyData: object) => set({previousAgencyData})
}))