export const pageStyles = {
    pagePaperStyle: {
        borderRadius: 0,
        padding: 3,
        maxHeight: "calc(100% - 64px)", 
        display: "flex", 
        flexDirection: "column", 
        width: '100%',
        // height: '100vh',
    }
}