/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { urls } from "../urls";
import { extendViewing, transform } from "utils";
import { generateQueryParams } from "utils";
import { QueryRequestBody } from "interfaces/common/QueryRequestBody"
import { SearchParams } from "customTypes/SearchParam";
import { useTableStore, useUserStore } from "../state";
import { TableNames } from "customEnums/TableNames";
import { Config, PageNames, UserRole } from "interfaces/config"
let localizedFormat = require('dayjs/plugin/localizedFormat')
var timezone = require('dayjs/plugin/timezone')

dayjs.extend(localizedFormat)
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(timezone)

export const useQueryTransactions = (
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    userORIs: any,
    userSecondaryORIs: any,
    userRole: any,
    searchParams: SearchParams,
    auditSearch: boolean
) => {

    const url = urls.QUERY;
    const [loading, setLoading] = useState(false);
    const [mappedData, setMappedData] = useState<any>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [error, setError] = useState<any>();
    const columnState = useTableStore(state => state.columnState)
    const pagination = useTableStore(state => state.pagination)
    const statusFilters = columnState.page["transaction-summary"]!.filters?.find(filter => filter.filterLabel === "Filter Status")
    const lastViewedFilters = columnState.page["transaction-summary"]!.filters?.find(filter => filter.filterLabel === "Last Viewed")
    const userConfig = useUserStore(state => state.userConfig)
    const config: Config = useUserStore(state => state.config)
    const { softLimit, hardLimit } = config.transactionViewing
    const { userRoles } = config.cognito
    const { isAdmin } = userConfig
    //const activeFilters = userConfig.activeFilters["transaction-summary"].filterValues

    console.log('debug runSearch searchParams: ', searchParams)

    useEffect(() => {
        console.log('debug reloading config')
        const controller = new AbortController();
        const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate")

        let filters = columnState.page["transaction-summary"]["transaction-summary"]?.filters
        //merge with activeFilters from userCofnig
        console.log('useQueryTransactions filters not present: ', { filters, userConfig, columnState })

        const filterLabels: string[] = [];
        if (filters) {
            console.log('101010010111101010 useQueryTransactions filters present: ', { filters, userConfig, columnState })

            filters.forEach((filter) => {
                console.log('useQueryTransactions filters present filter: ', filter)
                let filterValueKeys = Object.keys(filter.filterValues)
                filterValueKeys.forEach((key) => {
                    if (filter.filterValues[key].selected === true) {
                        filterLabels.push(filter.filterValues[key].label)
                    }
                })
            })
        }

        console.log(`debug useSetConfig useQueryTransactions useEffect dependencies fetchData conditionals: ${JSON.stringify({
            userORIs,
            userRole,
            filterLabels,
            runSearch: userORIs && userORIs.length && userRole && filterLabels.length > 0
        }, null, 2)}`)


        if (userORIs && userORIs.length && userRole && filterLabels.length > 0 && softLimit && userRoles.length > 0 && isAdmin) {


            console.log('101010110 debug useQuery useQueryTransactions useEffect dependencies RUNNING FETCH: ', {
                userRole,
                userRoles,
                hardLimit,
                isAdmin
            })

            const queryParams = generateQueryParams(softLimit.toString(), PageNames.TRANSACTION_SUMMARY, userConfig, hardLimit, isAdmin, searchParams, filterLabels, orderBy, order, param)

            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [
                            ...queryParams,
                            {
                                "value": order,
                                "name": "order"
                            }
                        ],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_TRANSACTION_TABLE_QUERY!

                    }
                ]
            }

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_TRANSACTION_TABLE_COUNT_QUERY!
                    }
                ]
            }

            const fetchData = async (queryDataRequest: QueryRequestBody, queryCountRequest: QueryRequestBody) => {
                console.log('debug runSearch searchParams: ', searchParams)
                let runSearch = false
                let searchParamsKeys = searchParams.length > 0 ? Object.keys(searchParams[0]) : []

                if (searchParamsKeys.length > 0) {
                    runSearch = searchParams.some((param: any) => param.hasOwnProperty("value"))
                } else {
                    runSearch = true
                }
                setLoading(true)


                if (runSearch) {
                    try {
                        const transactionalUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;
                        const data = await (await fetch(transactionalUrl, {
                            method: "POST",
                            credentials: "include",
                            body: JSON.stringify(queryDataRequest)
                        })).json();


                        const countData = await (await fetch(transactionalUrl, {
                            method: "POST",
                            credentials: "include",
                            body: JSON.stringify(queryCountRequest)
                        })).json();

                        setTotalRecords(countData['queriesResults'][0].results[0].count)


                        const { results } = data['queriesResults'][0];
                        const transformedData = results.map((obj: any, index: number) => { return transform(obj, index) });
                        setMappedData(transformedData);
                        setLoading(false);

                        if (auditSearch) {
                            //addAuditEvent("Search executed", "Search params: " +  JSON.stringify(queryParams), new Date(), undefined, undefined);
                        }

                    } catch (err) {
                        setError(err);
                        setLoading(false);
                    }
                }
            }
            //setLoading(true);
            console.log('debug pagination useQueryTransactions dependencies: ', {
                auditSearch,
                userRole,
                userORIsLength: userORIs.length,
                userSecondaryORIsLength: userSecondaryORIs.length,
                rowsPerPage: pageSize,
                page: currentPage,
                order,
                orderBy,
                searchParamsLength: searchParams.length,
                //filtersLength: columnState.page["transaction-summary"]!.filters?.length,
                //filters: columnState.page["transaction-summary"]!.filters,
                filterLabels
            })
            fetchData(queryDataRequest, queryCountRequest);
        }


        return () => controller.abort();
    }, [
        auditSearch,
        userRole,
        userORIs?.length,
        userSecondaryORIs?.length,
        pageSize,
        currentPage,
        order,
        orderBy,
        searchParams,
        searchParams?.length,
        columnState,
        columnState.page["transaction-summary"]["transaction-summary"]?.filters?.length,
        pagination[TableNames.TRANSACTION_SUMMARY],
        statusFilters,
        lastViewedFilters,
        softLimit,
        userRoles

    ]);

    return { data: mappedData, extendViewing, error, loading, totalRecords };
};
