import { AdvancedSearchAction } from "../../../customEnums";
import { States } from "../../../interfaces/common";
import { getCurrentEnv } from "../../../utils";

export const searchReducer = (state: any, action: any) => {
    console.log('debug select createSearch searchReducer state ', state)
    console.log('createSearch searchReducer action: ', action)
    const STATE = getCurrentEnv

    switch (action.type) {
        case AdvancedSearchAction.FIRST_NAME:
            return {
                ...state,
                firstName: action.value
            }

        case AdvancedSearchAction.LAST_NAME:
            console.log('createSearch update last name: ', action.value)
            return {
                ...state,
                lastName: action.value
            }

        case AdvancedSearchAction.STATUS:
            console.log('debug select searchReducer Case: Status')

            return {
                ...state,
                status: action.value
            }

        case AdvancedSearchAction.TYPE:
            return {
                ...state,
                transactionType: action.value
            }

        case AdvancedSearchAction.LOWER_DATE:
            return {
                ...state,
                lowerDate: action.value
            }

        case AdvancedSearchAction.UPPER_DATE:
            return {
                ...state,
                upperDate: action.value
            }

        case AdvancedSearchAction.RFP:
            if (STATE === States.GA) {
                return {
                    ...state,
                    stateRFPCode: action.value
                }
            } else {
                return {
                    ...state,
                    reasonFingerprinted: action.value
                }
            }


        case AdvancedSearchAction.TCN:
            return {
                ...state,
                tcn: action.value
            }

        /* removing since this is only run when the switch is activated. The switch has been removed for now. case AdvancedSearchAction.DAYS:
            return {
                ...state,
                days: action.value
                    ? STATE === States.ME
                        ? '36523'
                        : action.hardLimit.toString()
                    : action.softLimit.toString()
            }*/

        case AdvancedSearchAction.RESET:
            console.log('createSearch reset formState values state: ', state)
            //setSearchLength(0) move this to wherever reset action is performed
            return {
                days: action.softLimit.toString()
            }
    }
}

enum EditSubscriptionAction {
    DOB = 'DOB',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
    SET_VALUES = 'SET_VALUES',
    FIRST_NAME = 'FIRST_NAME',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
    LOWER_DATE = 'LOWER_DATE',
    UPPER_DATE = 'UPPER_DATE',
    RFP = 'RFP',
    DAYS = 'DAYS',
    RESET = 'RESET',
    TCN = 'TCN'
}

export const editSubscriptionReducer = (state: any, action: any) => {
    console.log('debug select createSearch searchReducer state ', state)
    console.log('createSearch searchReducer action: ', action)
    const STATE = getCurrentEnv

    switch (action.type) {

        case EditSubscriptionAction.SET_VALUES:
            console.log('set values to : ', action.value)
            return action.value

        case EditSubscriptionAction.EXPIRATION_DATE:
            return {
                ...state,
                expirationDate: action.value
            }

        case EditSubscriptionAction.DOB:
            return {
                ...state,
                dob: action.value
            }


    }
}

