import {
    Typography,
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Avatar,
    TextField,
    Grid
} from "@mui/material";
import { NotesStyle } from '../../../customStyles/index';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import UpArrow from '@mui/icons-material/KeyboardArrowUp';
import React from "react";
import { formatDate } from "utils/formatDate";
import { useMUITheme } from "theme/ThemeProvider";
import { NoteCardButtons } from './NoteCardButtons';
import { NoteCardProps } from '@propTypes/index';

const avatarInitials = (name: string) => {
    const initials = name.split(".").map((part: string) => part.charAt(0).toUpperCase());
    return initials.slice(0, 2).join("");
};

export const NoteCard: React.FC<NoteCardProps> = ({
    note,
    currentUser,
    activeSection,
    setActiveSection,
    showEditsById,
    showEdits,
    usersPermissions,
    setOpen,
    printNote,
    editNotes,
    updatedNotes,
    setUpdatedNotes,
    updateSelectedNote,
    updateCurrentNotes,
    showPreviousNotes,
    convertName
}) => {
    const noteCreator = note["creator"] === currentUser;
    const isEditMode = activeSection === (note["id"] || note["temporaryId"]);
    const { mode } = useMUITheme();

    return (
        <Card variant="outlined" sx={{ ...NotesStyle.notesCardStyle, bgcolor: mode === 'light' ? 'background.default' : 'background.paper' }} key={note["id"]}>
            <Grid display='flex' justifyContent='space-between'>
                <CardHeader avatar={<Avatar sx={{ ...NotesStyle.avatarStyle, bgcolor: noteCreator ? 'primary.main' : '#797A80' }}>
                    {avatarInitials(note["creator"])}
                </Avatar>
                }
                    subheader={convertName(note["creator"])}
                    subheaderTypographyProps={{
                        color: 'text.primary'
                    }}
                />
                <NoteCardButtons
                    setOpen={setOpen}
                    isEditMode={isEditMode}
                    printNote={printNote}
                    editNotes={editNotes}
                    note={note}
                    noteCreator={noteCreator}
                    usersPermissions={usersPermissions}
                    setActiveSection={setActiveSection}
                    setUpdatedNotes={setUpdatedNotes}
                    updatedNotes={updatedNotes}
                    updateSelectedNote={updateSelectedNote}
                    activeSection={activeSection}
                />
            </Grid>
            <CardContent sx={{ height: 'auto', overflowY: 'auto', pt: '0.5rem' }}>
                {isEditMode ? (
                    <TextField type='text' multiline fullWidth label="Notes" variant='outlined' maxRows={2} minRows={2} defaultValue={note["body"]} onChange={updateCurrentNotes} />
                ) : (
                    <Typography variant="body1" sx={{ fontFamily: 'Montserrat' }}>{note["body"]}</Typography>
                )}
                {!isEditMode && <Typography variant='body2' gutterBottom={false} sx={{ marginTop: '0.5rem' }}>{formatDate(note["datelastmodified"])}</Typography>}
            </CardContent>
            <CardActions sx={{ paddingTop: '0.5rem', pl: '1rem', pr: '1rem' }}>
                <div style={{ width: '100%' }}>
                    <Button
                        key={note["id"]}
                        size='small'
                        color='primary'
                        endIcon={(note["id"] === showEditsById && showEdits) ?
                            <UpArrow color='primary' />
                            :
                            <DownArrow color={note["attachments"].length === 0 ? 'inherit' : 'primary'} />}
                        disableRipple sx={{ padding: '0.25rem 0' }}
                        onClick={() => showPreviousNotes(note["id"])}
                        disabled={note["attachments"].length === 0}
                    >
                        {`SHOW EDIT (${note["attachments"].length || 0})`}
                    </Button>
                    {(note["id"] === showEditsById && showEdits) && note["attachments"].map((edits: any) => (
                        <div style={{ marginBottom: '1rem' }} key={note["id"]}>
                            <Typography variant='body2' color='text.primary' gutterBottom={false} sx={{ marginTop: '0.5rem' }}>
                                {formatDate(edits.datelastmodified)}
                            </Typography>
                            <Typography variant="body1" color='text.primary' sx={{ marginTop: '0.5rem' }}>
                                {edits.body}
                            </Typography>
                        </div>
                    ))}
                </div>
            </CardActions>
        </Card>
    );
};