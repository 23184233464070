export const tableBodyCellStyle = {
    paddingTop: "16px",
    paddingRight: "10px",
    paddingBottom: "16px",
    paddingLeft: "0px"
}

export const tableCellTypogrphyStyle = {
    fontSize: "14px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    pt: '5px'
}

export const tableCellChipStyle = {
    borderColor: 'red'
}