// import { useConfig } from '../hooks/useConfig';
import dayjs from 'dayjs';
import { useLoginStore } from "state/LoginStore";
import { useTableStore, useUserStore } from 'state';

export const GetMaintenanceSchedule = (tableName: string) => {
    const setAlertTitle = useLoginStore((state) => state.setAlertTitle)
    const setAlertDescription = useLoginStore((state) => state.setAlertDescription)
    const setSeverity = useLoginStore((state) => state.setSeverity)
    const setOpenAlert = useLoginStore((state) => state.setOpenAlert)
    const config = useUserStore(state => state.config)

    const startDate = config.scheduledMaintenance?.startDate
    const endDate = config.scheduledMaintenance?.endDate;
    const currentDate = dayjs().format('MM-DD-YYYY HH:mm:ss');
    let maintenanceActive;

    if (startDate && endDate) {
        if (currentDate >= startDate && currentDate <= endDate) {
            maintenanceActive = true
            setSeverity("warning")
            setOpenAlert(true)
            setAlertTitle("Maintenance Scheduled.")
            setAlertDescription("This website is under maintenance, please check back later.")
        } else {
            maintenanceActive = false
        }
    }

    return { maintenanceActive, startDate, endDate, currentDate }

}
