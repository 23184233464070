/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  useEditor,
  EditorContent,
  Editor,
} from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import {
  Divider,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import TextStyle from "@tiptap/extension-text-style";
import { Redact } from "./marks";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import printText from "utils/printText";
import NotesService from "utils/notesService";
import { useAudit } from "hooks/useAudit";
import { urls } from "urls"
import he from "he";
import PrintIcon from '@mui/icons-material/Print';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditIcon from '@mui/icons-material/Edit';
import { useMUITheme } from "theme/ThemeProvider";
import { useState, useCallback } from 'react';

interface CustomEditorProps {
  originalText: string;
  isEditable?: boolean;
  canPrint?: boolean;
  isVisible?: boolean;
  tcn: string;
  redactedJSON?: any;
  view?: string;
  changeView?: Function;
  canCreateNewRedaction?: boolean
  redactedText?: string;
  viewOriginal?: string;
  setCreatedRedaction?: Function;
  setRedactionTextCreation?: Function;
  redactedTextCreation?: string;
  createdRedaction?: boolean;
  allResponsesRecieved?: boolean;
  setView?: Function;
  setEditRedactedMode?: Function;
  editRedactedMode?: boolean;
  setTextChanges?: Function;
  textChanges: boolean;
  tabValue?: number;
  registrationId: string;
}

const getMarkPositions = (editor: Editor | null) => {
  const doc = editor?.view.state.doc;
  const marks: { start: number; end: number }[] = [];
  doc?.nodesBetween(0, doc.content.size, (node, pos) => {
    node.marks.forEach((mark) => {
      if (mark.type.name === "highlight") {
        marks.push({
          start: pos,
          end: pos + node.nodeSize,
        });
      }
    });
  });
  return marks;
};

const getSelectionTextContent = (
  editor: Editor | null,
  { start, end }: { start: number; end: number }
) => {
  if (!editor) return "";
  return editor?.state.doc.textBetween(start, end);
};

const CustomEditor: React.FC<CustomEditorProps> = ({
  originalText,
  redactedJSON,
  isVisible = true,
  canPrint = false,
  tcn,
  isEditable,
  view,
  viewOriginal,
  canCreateNewRedaction,
  setCreatedRedaction,
  setRedactionTextCreation,
  redactedTextCreation,
  createdRedaction,
  allResponsesRecieved,
  setView,
  setEditRedactedMode,
  editRedactedMode,
  setTextChanges,
  textChanges,
  redactedText,
}) => {
  const tempRedactionHTML = '<mark data-color="gray" style="background-color: gray; color: inherit"><span style="color: gray">'
  const redactedTextReformattedAsString = redactedText?.replace(/(<([^>]+)>)/g, "")
  const [highlighted, setHighlighted] = useState(false)
  const editor = useEditor({
    onUpdate({ editor }) {
      const sanitizeText = (text: string) => {
        console.log('redacted editor',editor.getText())
        console.log('redacted text test', redactedText?.replace(/(<([^>]+)>)/g, ""))
        console.log('has a change been made?',editor?.getText() !== redactedText?.replace(/(<([^>]+)>)/g, ""))
        const sanitizedInput = text.replace(/[&<>"']/g, "")
        const encodedText = he.encode(sanitizedInput);
        return encodedText;
      };
      const checkRedactionChanges = () => {
        if (editor?.getHTML().includes(tempRedactionHTML) || editor?.getText() !== redactedTextReformattedAsString) {
          setTextChanges?.(true)
        } else {
          setTextChanges?.(false)
        }
        console.log('testing textChanges', textChanges)
      }
      checkRedactionChanges()
      sanitizeText(editor?.getHTML());
    },
    extensions: [
      StarterKit,
      Highlight.configure({ multicolor: true }),
      Color.configure({
        types: ["textStyle"],
      }),
      TextStyle,
      Redact,
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editable: editRedactedMode,

  });
  
  const handleEdit = useCallback(() => {
    setEditRedactedMode?.((prev) => !prev)
  }, [])

  useEffect(() => {
    if (editRedactedMode) {
      editor?.setEditable(editRedactedMode)
    } else {
      editor?.setEditable(false)
    }
  })

  const original = useEditor({
    onUpdate({ editor }) {
      const sanitizeText = (text: string) => {
        const sanitizedInput = text.replace(/[&<>"']/g, "")
        const encodedText = he.encode(sanitizedInput);
        return encodedText;
      };
      sanitizeText(editor?.getHTML());
    },
    extensions: [
      StarterKit,
      Highlight.configure({ multicolor: true }),
      Color.configure({
        types: ["textStyle"],
      }),
      TextStyle,
    ],
    parseOptions: {
      preserveWhitespace: "full",
    },
    editable: false,
  });
  
  const url = urls.NOTES;
  const { registrationId = "" } = useParams();
  const { addAuditEvent } = useAudit();
  const { mode } = useMUITheme();
  const [showSubheader, setShowSubHeader] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  console.log('debugging registration ID: ', registrationId);

  useEffect(() => {
      const handleResize = () => {
          setScreenHeight(window.innerHeight);
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, [])

  let notesService = new NotesService(registrationId, "redact", url);

  useEffect(() => {
    notesService = new NotesService(registrationId, "redact", url);
  }, [url]);

  const saveRedactedText = () => {
    editor
      ?.chain()
      .focus()
      .selectAll()
      .updateAttributes("highlight", { color: "black" })
      .run();

    const redactions = getMarkPositions(editor);
    editor?.commands.forEach(redactions, ({ start, end }, { chain }) => {
      const range = { from: start, to: end };
      chain()
        .setTextSelection(range)
        .unsetAllMarks()
        //@ts-ignore
        .setRedaction({ color: "black" })
        .insertContentAt(
          range,
          getSelectionTextContent(editor, { start, end }).replace(/\S/g, "█")
        )
        .run();
      return true;
    });

    setRedactionTextCreation?.(editor?.getHTML());
    setCreatedRedaction?.(true)
    setEditRedactedMode?.(false)

    const hadRedaction = !!redactedJSON.length;
  
    if (hadRedaction) {
      notesService.updateNote(redactedJSON[0].id, editor?.getHTML() ?? "")
      addAuditEvent("Redacted - Edited", "Results / Response Data", new Date(), registrationId)
    } else {
      notesService.createNote(editor?.getHTML() ?? "");
      addAuditEvent("Redacted - Created", "Results / Response Data", new Date(), registrationId)

    }
  };

  const printRedactedText = (e: any) => {
    printText(editor?.getHTML() ?? "", "", "", {
      printBackground: false,
    })
    addAuditEvent("Printed - Redacted", "Results / Response Data", new Date(), registrationId)
  };

  const printOriginalText = () => {
    printText(viewOriginal ?? "", "", "", {
      printBackground: false,
    })
    addAuditEvent("Printed - Original", "Results / Response Data", new Date(), registrationId)
  }

  const editorObjectRef = useRef<Editor | null>(null);
  if (editor) {
    editorObjectRef.current = editor;
  }
  if (original) {
    editorObjectRef.current = original;
  }

  useEffect(() => {
    if (!originalText || !editor) return;
    editor.commands.setContent(originalText, true, {
      preserveWhitespace: "full",
    });

    if (viewOriginal) {
      original?.commands.setContent(viewOriginal, true, {
        preserveWhitespace: "full",
      })
    }
    console.log("debugging original text", originalText);
  }, [originalText, editor]);


  // to check if text is highlighted, enabling the redact and remove info buttons
  useEffect(() => {
    const handleSelectionUpdate = () => {
      const selection = editor?.state.selection || { from: 0, to: 0}
      const {from, to} = selection
      const newTextHighlighted = from !== to
      setHighlighted(newTextHighlighted)
      if (!newTextHighlighted) setHighlighted(false)
    }

    editor?.on('selectionUpdate', handleSelectionUpdate)
  },[editor])

  const handleExitEdit = () => {
    editor?.commands.setContent(originalText, true, {
      preserveWhitespace: "full",
    });
    setEditRedactedMode?.(false)
  }

  return (
    <>
      <Paper elevation={0} sx={{ mt: '1rem', border: '1px solid', borderColor: 'background.default', width: '100%'}}>
        {view === 'Original' ?
          <Card variant="outlined" sx={{height: screenHeight <= 1080 ? '25rem' : '35rem', backgroundColor: 'background.default'}}>
            <Grid display='flex' justifyContent='space-between' paddingTop='0.5rem' sx={{ backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }}>
              <CardHeader
                title='ORIGINAL'
                titleTypographyProps={{
                  fontSize: '20px',
                  color: 'text.primary',
                }}
                sx={{ pb: '0.5rem' }}
              />
              <Grid display='flex' alignItems='center' gap='1rem' marginRight='1rem'>
                {canPrint &&
                  <Button
                    onClick={printRedactedText}
                    color='secondary'
                    variant='outlined'
                    sx={{ color: 'text.primary'}}
                    startIcon={<PrintIcon color="secondary" />}
                  >
                    PRINT
                  </Button>
                }
                {canCreateNewRedaction && redactedJSON.length === 0 && !createdRedaction &&
                  <Button
                    color='secondary'
                    sx={{ marginRight: '1rem' }}
                    variant='contained'
                    onClick={() => setView?.('Original & Redacted')}
                    disabled={(allResponsesRecieved) ? false : true}
                  >
                    ADD REDACTION
                  </Button>}
              </Grid>
            </Grid>
            <CardHeader
              subheader='This response contains no redactions.'
              subheaderTypographyProps={{
                color: 'text.primary',

              }}
              sx={{ padding: '0 1rem 0.5rem 1rem', backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }}
            >
            </CardHeader>
            <CardContent sx={{ height: screenHeight <= 1080 ? '19.5rem' : '35rem', overflowY: 'auto', backgroundColor: 'background.default' }}>
              <EditorContent editor={editor} />
            </CardContent>
          </Card>
          : null}

        {isVisible && view === 'Redacted' && (editor || redactedTextCreation) ?
          <Card variant="outlined" sx={{height: screenHeight <= 1080 ? '25rem' : '35rem', backgroundColor: 'background.default'}}>
            <Grid display='flex' justifyContent='space-between' padding='0.25rem 0' sx={{ backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }}>
              <CardHeader
                subheader='REDACTED'
                subheaderTypographyProps={{
                  color: 'text.primary'
                }}
                sx={{ pb: '0.5rem' }}
              />
              <Grid display='flex' alignItems='center'>

                {canPrint &&
                  <Button
                    onClick={printRedactedText}
                    color='secondary'
                    variant='outlined'
                    sx={{ marginRight: '1rem', color: mode === 'dark' ? 'text.primary' : '' }}
                    startIcon={<PrintIcon color="secondary" />}
                  >
                    PRINT
                  </Button>
                }
              </Grid>
            </Grid>
            <CardContent sx={{ height: screenHeight <= 1080 ? '21.6rem' : '35rem', overflowY: 'auto', backgroundColor: 'background.default' }}>
              <EditorContent editor={editor} />
            </CardContent>
          </Card>
          : null}

        {view === 'Original & Redacted' && editor && original ?
          <Grid display='flex' justifyContent='space-between' sx={{ backgroundColor: 'common.black' }}>
            <Card variant="outlined" style={{ width: '49.5%', backgroundColor: 'background.default', height: screenHeight <= 1080 ? '25rem' : '37rem' }} > 
              <Grid display='flex' justifyContent='space-between' padding='0.25rem 0' sx={{ backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }}>
                <CardHeader
                  title='ORIGINAL'
                  titleTypographyProps={{
                    fontSize: '20px',
                    color: 'text.primary',
                  }}
                  sx={{ pb: '0.5rem' }}
                />
                <Grid display='flex' alignItems='center'>
                  {canPrint &&
                    <Button
                      onClick={printOriginalText}
                      color='secondary'
                      variant='outlined'
                      sx={{ marginRight: '0.5rem', color: mode === 'dark' ? 'text.primary' : '' }}
                      startIcon={<PrintIcon color="secondary" />}
                    >
                      PRINT
                    </Button>
                  }
                </Grid>
              </Grid>
              <CardHeader
                subheader='This response contains no redactions.'
                subheaderTypographyProps={{
                  color: 'text.primary',
                }}
                sx={{ padding: '0 0 0.5rem 1rem', backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }} />
              <CardContent sx={{ height: screenHeight <= 1080 ? '19.5rem' : '31.2rem', overflowY: 'auto', backgroundColor: 'background.default' }} >
                <EditorContent editor={original} style={{overflowY: 'auto'}}/>
              </CardContent>
            </Card>

            <Card variant="outlined" style={{ width: '49.5%', display: 'flex', flexDirection: 'column', height: screenHeight <= 1080 ? '25rem' : '37rem', backgroundColor: 'background.default'  }} >
              <Grid display='flex' justifyContent='space-between' padding='0.25rem 0' sx={{ backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }}>
                <CardHeader
                  title='REDACTED'
                  titleTypographyProps={{
                    fontSize: '20px',
                    color: 'text.primary',
                  }}

                />
                <Grid style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'space-around' }}>
                  {canPrint &&
                    <Button
                      onClick={printRedactedText}
                      color='secondary'
                      variant='outlined'
                      sx={{ color: mode === 'dark' ? 'text.primary' : '' }}
                      startIcon={<PrintIcon color="secondary" />}
                    >
                      PRINT
                    </Button>
                  }
                  {!editRedactedMode ?
                    <>
                      <Divider orientation="vertical" sx={{ height: '24px' }} />
                      {redactedJSON.length === 0 && !createdRedaction &&
                        <Button
                          variant='contained'
                          color='secondary'
                          sx={{ color: mode === 'dark' ? 'text.primary' : '' }}
                          onClick={() => setView?.('Original')}>
                          REVERT TO ORIGINAL
                        </Button>

                      }

                      <Button
                        variant='contained'
                        color='secondary'
                        sx={{ marginRight: '0.5rem', color: mode === 'dark' ? 'text.primary' : '' }}
                        onClick={handleEdit}
                        startIcon={<EditIcon />}
                      >
                        EDIT
                      </Button>
                    </> :
                    <>
                      <Divider orientation="vertical" sx={{ height: '24px' }} />
                      <Button
                        variant='outlined'
                        color='secondary'
                        sx={{ color: mode === 'dark' ? 'text.primary' : '' }}
                        onClick={(handleExitEdit)}>
                        EXIT WITHOUT SAVING
                      </Button>

                      <Button
                        variant='outlined'
                        color='secondary'
                        sx={{ marginRight: '0.5rem', color: mode === 'dark' ? 'text.primary' : '' }}
                        onClick={saveRedactedText} 
                        disabled={textChanges ? false : true}
                      >
                        SAVE CHANGES
                      </Button>
                    </>
                  }
                </Grid>
              </Grid>
              <CardHeader
                subheader={showSubheader ?
                  <>
                    <Grid >
                      To redact information, select the 'Edit' button. if you have not made any redactions, you can revert back to the original document.
                      <Button disableRipple variant='text' size='small' onClick={() => setShowSubHeader(false)}>SHOW LESS</Button>
                    </Grid>
                  </> :
                  <>
                    <Grid >
                      To redact information, select the 'Edit' button...
                      {/* To remove a redaction, highlight the text and select the 'Remove Redaction' button. Close redaction mode, and undo all redactions, using the 'cancel' button. */}
                      <Button variant='text' size='small' onClick={() => setShowSubHeader(true)}>SHOW MORE</Button>
                    </Grid>
                  </>
                }
                subheaderTypographyProps={{
                  color: 'text.primary',
                }}
                sx={{ padding: '0 0 0.5rem 1rem', backgroundColor: mode === 'dark' ? '#34343E' : '#F3F3F4' }} />
              <CardContent sx={{ height: screenHeight <= 1080 ? '19.5rem' : '35rem', overflowY: 'auto', backgroundColor: 'background.default' }} >
                <EditorContent editor={editor} />
              </CardContent>
              {isEditable && editRedactedMode &&
                <>
                  <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%' }} />
                  <CardActions sx={{ backgroundColor: 'background.default' }}>
                    <>
                      <Button
                        disabled={!highlighted}
                        color='primary'
                        sx={{
                          padding: '6px 8px',
                          gap: '8px'
                        }}
                        onClick={() =>
                          editor
                            .chain()
                            .focus()
                            .setHighlight({ color: "gray" })
                            .setColor("gray")
                            .run()
                        }>
                        <DriveFileRenameOutlineIcon sx={{ width: '20px', height: '20px' }} />
                        <Typography variant='body2'>
                          REDACT INFORMATION
                        </Typography>
                      </Button>
                      <Button
                        disabled={!highlighted}
                        color='primary'
                        sx={{
                          padding: '6px 8px',
                          gap: '8px'
                        }}
                        onClick={() =>
                          editor
                            .chain()
                            .focus()
                            .unsetMark("textStyle")
                            .unsetMark("highlight")
                            .run()
                        }
                      >
                        <EditOffIcon sx={{ width: '20px', height: '20px' }} />
                        <Typography variant='body2'>
                          REMOVE REDACTION
                        </Typography>
                      </Button>
                    </>
                  </CardActions>
                </>
              }
            </Card>
          </Grid>
          : null}
      </Paper>
    </>
  );
};

export default CustomEditor;
