import { create } from "zustand"

interface RapbackMaintenanceState {
    openDrawer: Function
    setOpenDrawerHandler: (addOriRfpGroups: Function) => void
    fullName: string
    setFullName: (fullName: string) => void
}

export const useRapbackMaintenanceStore = create<RapbackMaintenanceState>((set, get) => ({
    openDrawer: () => {},
    setOpenDrawerHandler: (openDrawer: Function) => set({openDrawer}),
    fullName: "",
    setFullName: (fullName: string) => set({fullName}),

}))