import dayjs from 'dayjs';

export const errorTypes = {
    'Password attempts exceeded': {
        title: 'Too many sign-in attempts.',
        description: 'Your account has been locked. Please try again in a couple of minutes.',
    },
    'Incorrect username or password.': {
        title: 'Invalid Credentials',
        description: "Please try again or select 'Forgot password.'",
    },
    'UserLambdaValidationException': {
        title: 'Unable To Validate Username',
        description: 'Please verify your information and try again.',
    },
    'CodeMismatchException': {
        title: 'Invalid Verification Code.',
        description: 'Please Try again. You have 3 minutes to complete the authentication.',
    },
    'EnableSoftwareTokenMFAException': {
        title: 'Invalid Verification Code.',
        description: 'Please Try again. You have 3 minutes to complete the authentication.',
    },
    'Invalid session for the user, session is expired.': {
        title: 'Invalid session for the user, session is expired.',
        description: 'Please click cancel to restart.',
    },
    'Password has been used within the last year': {
        title: 'Please try an alternate password',
        description: 'Password has been used within the last year, please try an alternate password.',
    },
    'Attempt limit exceeded': {
        title: 'Attempt limit exceeded',
        description: 'Attempt limit exceeded, please try after some time.',
    },
    "Invalid verification code provided, please try again.": {
        title: "Invalid verification code provided, please try again.",
        description: '',
    },
    "LimitExceededException": {
        title: "Attempt limit exceeded",
        description: 'Please try after some time.',
    },
    "UserNotFoundException": {
        title: "Incorrect Username",
        description: 'Please check your username and try again.',
    },
    "PostConfirmation failed with error Password has been used within the last year, please try an alternate password.": {
        title: "Please try an alternate password",
        description: 'Password has been used within the last year, please try an alternate password.', 
    }
};

export enum DateErrorType {
    EXCEDES = 'EXCEDES',
    PRECEDES = 'PRECEDES',
    REQUIRED = 'REQUIRED',
    ADMINISTRATOR = 'ADMINISTRATOR'
}

export const buildDateErrorMessage = (dateType: string, errorTypes: DateErrorType, date?: any, ) => {
    let errorMessage: string = '';
    switch (errorTypes) {
        case DateErrorType.EXCEDES:
            if (date) {
                errorMessage = `${dateType} cannot exceed ${date.format('MM/DD/YYYY')}.`
            };
            break;
        case DateErrorType.PRECEDES:
            errorMessage = `${dateType} cannot precede or equal the current date, ${dayjs().format('MM/DD/YYYY')}.`
            break;
        case DateErrorType.REQUIRED:
            errorMessage = `${dateType} is required.`
            break;
        case DateErrorType.ADMINISTRATOR:
            errorMessage = `${dateType} is malformatted. Please contact an administrator for assistance.`
            break;
    }
    return errorMessage
}


