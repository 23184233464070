export const AgencyManagementStyles = {
    addAgencyModalBoxStyle: {
        position: 'absolute',
        width: '56.25rem',
        height: 'auto',
        padding: '0 1.5rem',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0.25rem'
    },
    editAgencyModalBox: {
        padding: '1rem 2rem ', 
        width: '40rem', 
        minHeight: '100vh', 
        backgroundColor: 'background.default', 
        flex: '1', 
        overflowY: 'auto'
    }
}