import React, { useEffect } from 'react'
import { Grid, CircularProgress, Container } from '@mui/material'
import { useAuditHistory, useSetConfig } from 'hooks';
import { CustomTable } from "common";
import { useTableStore } from 'state/TableStore';
import { useLocation } from "react-router-dom";
import { extractPageName } from "./CustomTable/helpers";
import { TableNames } from 'customEnums/TableNames';

export const AuditHistoryTable: React.FC<any> = ({ id, name }) => {
    const { auditMappedData, auditLoading, totalAuditRecords } = useAuditHistory(id)
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const tableConfig = useTableStore(state => state.tableConfig)
    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, name)
    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)

    useEffect(() => {
        setPagination({ ...pagination, [name]: { page: 0, rowsPerPage: 5 } })
    }, [])

    const notesLoadingContainer = {
        display: 'flex',
        padding: '1.5rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        alignSelf: 'stretch',
        backgroundColor: name !== "rb-sub-audit-history" || name !== "rb-summary-audit-history" ? 'common.black' : 'background.default'
    }

    console.log('auditMappedData AuditHistoryTable: ', auditMappedData)

    if (auditLoading) {
        return (
            <Grid sx={notesLoadingContainer}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Container disableGutters maxWidth={false} sx={{ width: "100%" }}>
            {
                tableHeaderCells &&
                <CustomTable
                    tableConfig={tableConfig}
                    tableName={name}
                    savedSearchState={[{}, () => { }]}
                    pageSize={rowsPerPage}
                    totalRecords={totalAuditRecords}
                    currentPage={page}
                    hideSearch={true}
                    defaultSortColumn="date"
                    rows={auditMappedData?.length ? auditMappedData : []}
                />
            }

        </Container>
    )
}