/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Route } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import { AppLayout } from "./components/layouts/AppLayout";
import { AuthLayout } from "./components/layouts/AuthLayout";
import {
  Login,
  TransactionSummary,
  ApplicantDetails,
  AgencyManagement,
  RapBackSummary,
  BatchPrinting,
  UserManagement,
  RapbackMaintenance,
  RapbackPrenotifications,
  RapbackValidations
} from "./pages";
import { ProtectedLayout } from "components/layouts/ProtectedLayout";

const getUserData = async () => {
  await new Promise((resolve) => setTimeout(resolve, 3000));
  return new URLSearchParams(document.cookie).get("token");
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<AppLayout />}>
        <Route index element={<Login />} />
      </Route>
      <Route path="/app" element={<ProtectedLayout/>}>
        <Route path="transaction-summary" element={<TransactionSummary />} />
        <Route path="agency-management" element={<AgencyManagement />}  />
        <Route path="rapback-summary" element={<RapBackSummary />} />
        <Route path="user-management" element={<UserManagement />} />
        {/* <Route path="permissions-settings" element={<PermissionsSettings />}/> */}
        <Route path="batch-printing" element={<BatchPrinting/>}/>
        <Route path="rapback-maintenance" element={<RapbackMaintenance/>}/>
        <Route path="rapback-prenotifications" element={<RapbackPrenotifications/>}/>
        <Route path="rapback-validations" element={<RapbackValidations/>}/>
       
        <Route
          path="applicant-details/:registrationId"
          element={<ApplicantDetails />}
        />
      </Route>
    </Route>
  )
);
