/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { FC } from "react";
import { DialogContentText } from '@mui/material'

interface TimeoutWarningProps {
  timeLeft: number;
}

const formatCountdownFriendly = (remainInMs: number) => {
  const remainInSecond = Math.round(remainInMs / 1000);
  const mins = Math.floor(remainInSecond / 60);
  const secs = remainInSecond % 60;
  const formatTime =  `${String(mins)}:${String(secs).padStart(2, '0')} ${mins === 0 ? 'seconds' : 'minutes'}`;
  return formatTime;
};

export const TimeoutWarning: FC<TimeoutWarningProps> = ({ timeLeft }) => (
    <DialogContentText>
      You’ve been inactive for a while. For your security, we will end your
      session in <b>{formatCountdownFriendly(timeLeft)}</b>.
    </DialogContentText>  
);
