export const LoginStyles = {
    mfaCardContentStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem',
        alignSelf: 'stretch',
        width: '31.5rem'
      },
      cardContentStyle : {
        display: 'flex',
        width: '31.5rem',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: ' flex-start',
        gap: 'var(--2, 1rem)',
        alignSelf: 'stretch'
      },
       qrCodeStyle : {
        width: '190px',
        height: '190px',
        bgcolor: 'white',
        borderRadius: '0.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        mt: '0.5rem'
      },
       qrCodeVerificationStyle : {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
      },
       authCodeContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      },
       authCodeTextStyle: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: '25%',
        wordWrap: 'break-word',
        textAlign: 'center',
      }, 
      formStyle: {
        width: '504px', 
        paddingTop: '0.5rem', 
        paddingBottom: '0.5rem'
      },
      pwRequirementStyle: {
        listStyleType: 'disc', 
        padding: '0 0 1.5rem 2.8rem'
      },
      copySuccessStyle: {
        color: '#8CB98E', 
        marginBottom: 0, 
        marginTop: 0, 
        alignItems: 'center',
         display: 'flex'
      }
}