import React, { useCallback } from 'react'
import { Container, Button, Tooltip, Badge, IconButton, Menu, MenuItem, Typography, Divider, FormControlLabel, Checkbox, Switch } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTableStore } from "state/TableStore";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomTableStyle } from 'customStyles/CustomTableStyle';
// import { useConfig } from 'hooks/useConfig'
import { ColumnStateActions } from "customEnums/ColumnState";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { HeaderCell } from 'interfaces/config';


export const TableTools = ({ tableName, resetTable }) => {
    const locationObj = useLocation()
    const pageName = locationObj.pathname.slice(1).replace('app/', "")
    const columnState = useTableStore(state => state.columnState!)
    const { columnStateDispatch } = columnState
    const headerButtonConfig = useTableStore((state) => state.headerButtonConfig)
    const { text, buttonLabel, handler, disabled, headerButtonIcon } = headerButtonConfig
    const tableConfig = useTableStore(state => state.tableConfig)
    const { useQueryPagination, manageColumns, filter, tables, columnVisibility, filterList, filters, tableHeaderCells } = tableConfig[tableName]
    const setPagination = useTableStore(state => state.setPagination)
    const pagination = useTableStore(state => state.pagination)

    //Local State
    const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null)
    const openFilter = Boolean(filterAnchorEl);
    const [showAllColumns, setShowAll] = useState(true)
    const [numFiltersDeselected, setNumFiltersDeselected] = useState(0)
    const [mcAnchorEl, setMCAnchorEl] = useState(null);
    const [openFilters, setOpenFilters] = useState(columnState.page[pageName][tableName]?.filters?.length > 0 ? Array(columnState.page[pageName][tableName].filters.length).fill(true) : []);
    const STATE = process.env.REACT_APP_CONFIG_STATE!;

    const openManageColumns = Boolean(mcAnchorEl);
    const manageColumnsId = openManageColumns ? 'manage-columns-popover' : undefined;
    //const tableHeaderCells = tables.find((headerCell: HeaderCell) => headerCell.tableName === tableName)?.tableHeaderCells

    console.log(`1010101010 debug updated config columnState && tableHeaderCells in TableTools: `, {
        columnState,
        tableHeaderCells,
        tableName,
        filter,
        filterList,
        filtersLength: filterList.length,
        renderTableTools: filter && filterList.length > 0,
        tableConfigTableName: tableConfig.tableName
    })

    useEffect(() => {
        console.log(`1010101010 debug updated config TableTools useEffect tableConfig:  `, tableConfig)
    }, [tableConfig])

    useEffect(() => {
        const columnVisibility: Array<any> = []
        Object.keys(columnState.page[pageName][tableName]?.columnVisibility).map((key: string) => {
            columnVisibility.push(columnState.page[pageName][tableName].columnVisibility[key])
        })
        if (columnVisibility.some((key: any) => key !== "middleName" && key === false)) {
            setShowAll(false)
        } else {
            setShowAll(true)
        }

        const filterList: Array<any> = []
        const filtersDeselected: Array<any> = []
        columnState.page[pageName][tableName].filters?.map((filter: any, index) => {
            Object.keys(filter.filterValues).map((key: string) => {
                if (!filter.filterValues[key].selected) {
                    filtersDeselected.push(filter.filterValues[key])
                    setNumFiltersDeselected(filtersDeselected.length)
                } else {
                    if (!filtersDeselected.length) {
                        setNumFiltersDeselected(filtersDeselected.length)
                    }
                }
                filterList.push(filter.filterValues[key])
            })
        })
    }, [columnState, filter])

    const handleColumnUpdate = useCallback((dataIndex: string, isChecked: boolean, column?: string) => {
        console.log('101010010111101010 handleColumnUpdate params:  ', { dataIndex, column, isChecked, columnState })
        let filterKeys: any = [];

        columnState.page[pageName][tableName].filters.map((filter: any) => {
            Object.keys(filter.filterValues).map((data: any) => {
                filterKeys.push(data)
            })
        })

        console.log(`debug TableTools filter column ${column} dataIndex: ${dataIndex}`)
        console.log(`debug TableTools filter column ${filterKeys}.includes(${dataIndex}): ${filterKeys.includes(dataIndex)}`)

        if (filterKeys.includes(dataIndex)) {
            console.log('columnState status filter: ', dataIndex, pageName)
            columnStateDispatch({ type: ColumnStateActions.TOGGLE_FILTER, filter: dataIndex, page: pageName, column: column, tableName })

        } else {
            columnStateDispatch({ type: ColumnStateActions.TOGGLE_COLUMN, dataIndex, page: pageName, tableName })
        }

    }, [filter])

    const handleHideAll = () => {
        columnStateDispatch({ type: ColumnStateActions.HIDE_ALL_COLUMNS, page: pageName, tableName })
        setShowAll(true)
    }

    const handleShowAll = () => {
        console.log('debug TableTools filter column show all running')
        columnStateDispatch({ type: ColumnStateActions.SHOW_ALL_COLUMNS, page: pageName, tableName })
        setShowAll(false)
    }

    const handleResetFilterStatus = () => {
        console.log('101011011010 debug columnStateReducer handleResetFilterStatus ran')
        columnStateDispatch({ type: ColumnStateActions.RESET_FILTERS, page: pageName, filterList, tableName })
    }

    const handleOpenFilter = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setFilterAnchorEl(e.currentTarget)
    }

    const handleCloseFilter = (e: any) => {
        e.stopPropagation()
        setFilterAnchorEl(null)
    }

    const handleReset = useCallback(() => {
        console.log('101011011010 debug columnStateReducer columnVisibility', columnVisibility)
        columnStateDispatch({ type: ColumnStateActions.RESET_COLUMNS, page: pageName, columnVisibility, tableName })
        handleResetFilterStatus()
        setPagination({ ...pagination, page: useQueryPagination ? 1 : 0, rowsPerPage: 25, sortOrder: "desc" })
    }, [columnVisibility])

    const handleOpenManageColumns = (e: any) => {
        setMCAnchorEl(e.currentTarget)
    }

    const handleCloseManageColumns = (e: any) => {
        e.stopPropagation()
        setMCAnchorEl(null);
    };

    const toggleFilterMenu = (index) => {
        const updatedOpenFilters = [...openFilters];
        updatedOpenFilters[index] = !updatedOpenFilters[index];
        setOpenFilters(updatedOpenFilters);
    };

    return (
        <Container disableGutters maxWidth={false} sx={CustomTableStyle.tableTopButtons}>
            {
                //Check pageName another way
                (pageName === "user-management" || pageName === 'batch-printing' || (pageName === 'agency-management' && STATE !== "ga")) &&
                <Button
                    onClick={() => handler()}
                    aria-label={buttonLabel}
                    size="medium"
                    color="secondary"
                    disabled={disabled}
                    variant="contained"
                    startIcon={headerButtonIcon}
                    sx={CustomTableStyle.printButtonStyle}>
                    {text}
                </Button>
            }
            {
                filter && filterList.length > 0 && (
                    <>
                        <Tooltip title="Table Filter">
                            <Badge color="primary" badgeContent={numFiltersDeselected > 0 ? numFiltersDeselected : null}>
                                <IconButton onClick={(e) => handleOpenFilter(e)} sx={{ color: 'text.secondary' }}>
                                    <FilterAltIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        <Menu 
                            onClose={(e) => handleCloseFilter(e)}
                            slotProps={{
                                paper: { sx: { border: "none", display: 'flex', flexDirection: "column" } }
                            }}
                            open={openFilter}
                            anchorEl={filterAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}>

                            {filterList?.map((filter: any, index: any) => (
                                <div key={index}>
                                    <MenuItem key={index} onClick={() => toggleFilterMenu(index)}>
                                        <Typography sx={{ flex: 5, mr: pageName === "rapback-validations" ? '0.5rem' : '' }}>{filter.filterLabel}</Typography>
                                        {openFilters[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                    </MenuItem>
                                    <Divider />
                                    {openFilters[index] && Object.keys(filter.filterValues).map((key: any, innerIndex: any) => {
                                        const isChecked = columnState.page[pageName][tableName]?.filters[index].filterValues[key]?.selected;
                                        return (
                                            <MenuItem key={innerIndex} onClick={(e) => { e.stopPropagation(); handleColumnUpdate(key, isChecked, filter.filterColumn) }}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={isChecked} onClick={(e) => e.stopPropagation()}/>}
                                                    label={filter.filterValues[key]?.label}
                                                />
                                            </MenuItem>
                                        );
                                    })}
                                </div>
                            ))}
                        </Menu>
                    </>
                )
            }
            <Tooltip title="Reset Table">
                <IconButton onClick={(e: any) => {
                    e.stopPropagation();
                    handleReset?.() //Resets Column visibility and Status Filter
                    resetTable() //Resets table search
                }}
                    sx={CustomTableStyle.iconContainer}>
                    <RestartAltIcon sx={{ color: 'action.active' }} aria-label="reset-table" />
                </IconButton>
            </Tooltip>
            {
                manageColumns && (
                    <>
                        <Tooltip title="Manage Columns">
                            <IconButton onClick={(e: any) => {
                                e.stopPropagation();
                                handleOpenManageColumns(e)
                            }}
                                sx={CustomTableStyle.iconContainer}>
                                <SettingsIcon sx={{ color: 'action.active' }} aria-label="manage-columns" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            onClose={(e) => handleCloseManageColumns(e)}
                            slotProps={{ paper: { sx: { backgroundColor: "background.default", border: "none", display: 'flex', flexDirection: "column", r: '2rem' } } }}
                            id={manageColumnsId}
                            open={openManageColumns}
                            disableAutoFocus
                            anchorEl={mcAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                            <MenuItem key="title" sx={{ pointerEvents: 'none' }}>
                                <Container disableGutters sx={{ width: "100%", display: "flex" }}>
                                    <Typography sx={{ flex: 5 }}>Manage Columns</Typography>
                                </Container>
                            </MenuItem>
                            <Divider />

                            {
                                (tableHeaderCells?.map((cell: any, index) => {
                                    if (cell.dataIndex !== "checkbox") {
                                        return (
                                            <MenuItem key={index}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        checked={columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex]}
                                                        onChange={() => handleColumnUpdate(cell.dataIndex, columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex])}
                                                        color='secondary'

                                                    />}
                                                    label={cell.dataIndex === 'checkbox' ? 'Checkbox' : cell.label}
                                                    disabled={cell.dataIndex === 'checkbox' ? true : false}
                                                />
                                            </MenuItem>
                                        )
                                    }
                                })
                                )
                            }
                            <MenuItem key={0} sx={{
                                '&:hover': {
                                    cursor: 'auto',
                                    background: 'none'
                                }
                            }}>
                                <Container disableGutters sx={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
                                    {
                                        showAllColumns
                                            ? <Button onClick={() => handleHideAll?.()} variant='text'>HIDE ALL</Button>
                                            : <Button color='primary' onClick={() => handleShowAll?.()} variant='text'>SHOW ALL</Button>
                                    }
                                    <Button onClick={() => handleReset?.()} variant='text'>RESET</Button>
                                </Container>
                            </MenuItem>
                        </Menu>
                    </>
                )
            }


        </Container>
    )
}
