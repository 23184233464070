import { createTheme } from '@mui/material/styles';
import light from './sass/_lightTheme.module.scss';
import { Typography }  from './typography';


const lightTheme = createTheme({
    components: {
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    width: "100%",
                    paddingRight: "10px"
                },
                icon: {
                    marginLeft: "auto"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.css-1y4j4gs-MuiPaper-root-MuiDialog-paper': {
                        backgroundColor: light.primaryBg
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                    root: {
                        '&.Mui-disabled .css-1vpgqdb-MuiSvgIcon-root ': {
                            color: '#BDBDBD'
                        }
                    }
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        color: '#BC8FFF',
                        borderWidth: '2px',
                        '&:hover': {
                            borderWidth: '2px'
                        }
                    }
                },
            ]
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error' : {
                        color: '#D1031C'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-error' : {
                        color: '#D1031C'
                    }
                },
                asterisk: {
                    '&.Mui-error': {
                        color: '#D1031C'
                        
                    } 
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D1031C',
                        color: '#D1031C'
                    }
                }
            }
        },
        MuiSwitch:  {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: light.switchDeselected
                },
                colorSecondary: {
                    "&$checked": {
                      // Controls checked color for the thumb
                      color: light.primaryMain
                    }
                  },
                  track: {
                    // Controls default (unchecked) color for the track
                    //opacity: 0.01,
                    backgroundColor: light.switchTrackDeselected,
                    /*"$checked$checked + &": {
                      // Controls checked color for the track
                      opacity: 0.7,
                      backgroundColor: "#fff"
                    }*/
                  }
            }
            
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    '&.MuiPopover-paper': {
                        backgroundColor: light.primaryBg
                    }
                }
            }
        }
    },
    palette: {
        mode: 'light',
        common: {
            black: '#FFF', // using this for applicant details background
            white: '#fff'
        },
        primary: {
            main: light.primaryMain
        },
        secondary: { // using this for button color
            main: light.primaryButton,
            contrastText: light.primaryBg

        },
        background: {
            default: light.primaryBg, // used for table, background of content
            paper: light.primaryBgAlt // use for body
        },
        text: {
            primary: light.primaryText,
            secondary: light.secondaryText
        },
        action: {
            active: light.secondaryText,
            selected: light.actionSelected
        },
        success: {
            main: light.successMain,
            dark: light.sucessBorder,
            contrastText: light.successText
        },
        error: {
            main: light.errorMain,
            dark: light.errorBorder,
            contrastText: light.errorText
        }, 
        info: {
            main: light.infoMain,
            dark: light.infoBorder,
            contrastText: light.infoText
        },
        warning: {
            main: light.warningMain,
            dark: light.warningBorder,
            contrastText: light.warningText
        },
        divider: '#10111C1F'
    },
    typography: Typography,

})

console.log('light theme: ', lightTheme)

export default lightTheme;

