import { Button } from '@mui/material';
import { TableConfig } from 'interfaces/config';
import React from 'react';
import { useTableStore } from 'state';

interface CustomTableCellProps {
    row: any
    value: string
    index: string
    tableConfig: TableConfig,
    keyIndex: number
}

export const ButtonTableCell: React.FC<CustomTableCellProps> = ({ keyIndex, row, value, index, tableConfig }) => {

    const buttonCellHandlers = useTableStore(state => state.buttonCellHandlers)
    //const tableConfig = useTableStore(state => state.tableConfig)
    console.log('debug tableConfig ButtonTableCell buttonCellHandlers: ', buttonCellHandlers)
    const { clickHandler } = buttonCellHandlers[index]
    console.log('debug tableConfig ButtonTableCell clickHandler: ', clickHandler)

    //const buttonHandlerParams = useTableStore(state => state.buttonHandlerParams)

    return (

        <Button key={keyIndex} onClick={(e) => clickHandler(e, row)}
            sx={{ justifyContent: "flex-start", minWidth: "max-content", padding: 0 }}
            variant="text">
            {value}
        </Button>

    )
}