
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from "react";
import { urls } from '../urls'


export const useExpectedResponse = (registrationId: string) => {
    const [expectedResponse, setExpectedResponse] = React.useState<any>();
    const reportingUrl = urls.REPORTING;

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchReportingsUrl = `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/transactions/expectedResponses/${registrationId}`;
        
        const fetchUrls = async () => {
            try {
                const { numResponses } = await (await fetch(fetchReportingsUrl, {
                    credentials: "include",
                    signal
                })).json();

                setExpectedResponse(numResponses.length);

            } catch (err: any) {
                console.log('use expected response error: ', err);
            }
        }
        
        fetchUrls();
        
        const refreshUrlInterval = setInterval(() => {
            fetchUrls();
        },60000);

        return () => {
            clearInterval(refreshUrlInterval);
            controller.abort();
        }
    }, [registrationId, reportingUrl])

    return { expectedResponse };
};
