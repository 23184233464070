import React, { useCallback, useEffect, useState } from 'react';
import {
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Box,
    Skeleton,
    Grid,
    Typography
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import InboxIcon from '@mui/icons-material/Inbox';
import { useTableStore } from 'state/TableStore';
import { useLocation } from 'react-router-dom';
import { Actions } from 'pages/TransactionSummary/components';
import { CustomTableCellSelect } from './CustomTableCellSelect';
import { SimpleTableCell } from './SimpleTableCell';
import dayjs from 'dayjs';
import { extractPageName } from '../helpers';
import { TableHeaderCell, useUserStore } from 'state';
import { ButtonTableCell } from './ButtonTableCell';
import { ShowHideTableCell } from './ShowHideTableCell';
import { CustomChip } from './CustomChip'
import { tableBodyCellStyle } from '../styles';
import { CellType } from 'customEnums/CellType';

export const CustomTableBody = ({
    openModalBlur,
    openAgencyEdit,
    loading,
    visibleRows,
    rowClickHandler,
    tableName,
    tableConfig
}) => {

    const { tableHeaderCells, userRoles } = tableConfig[tableName]
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const columnState = useTableStore(state => state.columnState)
    const pagination = useTableStore(state => state.pagination)
    const { rowsPerPage } = pagination[tableName]
    const userConfig = useUserStore(state => state.userConfig)
    const { usersPermissions } = userConfig;
    const currentRow = useTableStore((state) => state.currentRow)
    const setCurrentRow = useTableStore((state) => state.setCurrentRow)
    const selected = useTableStore(state => state.selected)
    const STATE = process.env.REACT_APP_CONFIG_STATE!;

    const isSelected = (tcn: string) => {
        return selected?.indexOf(tcn) !== -1;  //this checks tcn which it only gets from select all
    }

    const headerCellsLength = tableHeaderCells?.length
    const widthPercentage = 100 / headerCellsLength

    const noHoverBg = [
        'rb-summary-audit-history',
        'rb-sub-audit-history',
        "reject-codes",
        "applicant-details",
        "incoming-response",
        "outgoing-transmission",
        "audit-history",
        "status-history",
        "adjudication-docs"
    ];

    const tableRowStyle = {
        cursor: usersPermissions.editExistingAgencies && noHoverBg.includes(pageName) || noHoverBg.includes(tableName) || (!usersPermissions.viewRapBackDetails && pageName === 'rapback-summary') ? '' : "pointer",
        '&:hover': {
            backgroundColor: usersPermissions.editExistingAgencies && noHoverBg.includes(pageName) || noHoverBg.includes(tableName) || (!usersPermissions.viewRapBackDetails && pageName === 'rapback-summary')
                ? '' : "background.paper"
        },
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }

    // @ts-ignore
    const SkeletonLoading: React.FC<any> = ({ rowsPerPage }) => {
        let activeCells: string[] = []
        tableHeaderCells?.forEach((cell: any) => {
            if (columnState.page[pageName][tableName].columnVisibility[cell.dataIndex]) {
                activeCells.push(cell.dataIndex)
            }
        })

        const headerCellsLength = activeCells.length

        return Array.from({ length: rowsPerPage }).map((row, index) => (
            <TableRow key={index} sx={{ border: 0 }}>
                {
                    Array.from({ length: headerCellsLength }).map((cell: any, index) => {
                        return (
                            <TableCell key={index}>
                                <Skeleton sx={{ height: '25px' }} />
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        ))
    }

    const getLastViewedChildren = (row) => {
        if (row.viewedDate != "N/A") {
            return dayjs(row.viewedDate).format('MMM D, YYYY h:mm:ss A')
        } else {
            return (
                <>
                    <CircleIcon color='primary' sx={{ pt: '0.75rem', mr: '0.25rem' }} />
                    Not Viewed
                </>
            )
        }
    }

    const getChildren = (row, dataIndex) => {
        const simpleTableCellChildrenMap = {
            lastViewedDate: getLastViewedChildren(row)
        }
        return simpleTableCellChildrenMap[dataIndex]
    }

    const [rowToDisplay, setRowToDisplay] = useState('')

    const toggleVisibility = useCallback((key) => {
        console.log('toggleVisibility ', { key, rowToDisplay })

        if (rowToDisplay !== key) {
            setRowToDisplay(key)

        } else {
            setRowToDisplay('')
        }

    }, [rowToDisplay])



    const getCellComponentType = useCallback((row, cell, type = { type: "", value: "" }, index = 0) => {
        let cellType = type.type != "" ? type.type : cell.cellType
        let simpleValue = type.value != "" ? type.value : row[cell.dataIndex]
        const isCurrentRowClicked = currentRow === row.key
        const isItemSelected = isSelected(pageName === "batch-printing" && row.transactionNumber ? row.transactionNumber : "") //this always tcn
        const isCellRevealed = rowToDisplay === row.key

        switch (cellType) {
            case CellType.SIMPLE:
                console.log(`debug getCellComponentType in Simple ${cell.dataIndex} is ${simpleValue}  `)
                return <SimpleTableCell value={simpleValue} cell={cell}>
                    {

                        cell.hasChildren && (
                            getChildren(row, cell.dataIndex)
                        )
                    }
                </SimpleTableCell>

            case CellType.MULTI:
                let value = row[cell.dataIndex]
                return Object.keys(cell.cellOptions).map((type, index) => {
                    if (cell.cellOptions[type].includes(value)) {
                        console.log(`debug getCellComponentType in Multi calling getCellComponentType with params ${JSON.stringify({
                            row: row.key,
                            value: row[cell.dataIndex],
                        }, null, 2)}`)
                        return getCellComponentType(row, cell, { type, value }, index)
                    }
                })

            case CellType.BUTTON:
                return <ButtonTableCell keyIndex={index} tableConfig={tableConfig} value={row[cell.dataIndex]} index={cell.dataIndex} row={row} />
            case CellType.ACTIONS:
                return <Actions
                    row={row}
                    pageName={pageName}
                    isCurrentRowClicked={isCurrentRowClicked}
                    rowClickHandler={(e) => rowClickHandler(e, row)}
                />
            case CellType.SHOWHIDE:
                return <ShowHideTableCell isCellRevealed={isCellRevealed} toggleVisibility={toggleVisibility} row={row} value={row[cell.dataIndex]} cell={cell} />
            case CellType.CHECKBOX:
                return <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': `select-${cell.dataIndex}` }}
                />
            case CellType.CUSTOM_CHIP:
                return <CustomChip rowData={row}
                    dataIndex={cell.dataIndex} />
            case CellType.CUSTOM_TABLE_SELECT:
                if (cell.dataIndex === "securityLevel") {
                    return (
                        <CustomTableCellSelect
                            tableName={tableName}
                            rowData={row}
                            dataIndex={cell.dataIndex}
                            menuItems={userRoles}
                            value={row.securityLevel}
                        />
                    )
                } else if (cell.dataIndex === "status") {
                    return (
                        <CustomTableCellSelect
                            tableName={tableName}
                            rowData={row}
                            dataIndex={cell.dataIndex}
                            menuItems={cell.menuItems}
                            value={row.status}
                        />
                    )
                }
                break;
        }
    }, [tableName, rowToDisplay])

    console.log('debug pagination CustomTableBody visibleRows: ', { visibleRows, tableName })

    return (
        <TableBody key={tableName} sx={{ minWidth: "1760px", backgroundColor: tableName !== "rapback-maintenance" && tableName && tableName !== 'rb-sub-audit-history' ? 'common.black' : 'background.default', }} >
            {
                loading
                    ? <SkeletonLoading key={rowsPerPage} rowsPerPage={rowsPerPage} />
                    : visibleRows?.length === 0
                        ? <TableRow key={0}>
                            <TableCell colSpan={Object.keys(columnState.page[pageName][tableName].columnVisibility!).length} align="center">
                                <Grid>
                                    <InboxIcon sx={{ color: 'text.primary' }} />
                                    <Typography variant="subtitle2" color='text.primary'>
                                        No Results
                                    </Typography>
                                </Grid>
                            </TableCell>
                        </TableRow>

                        : visibleRows?.map(
                            (row: any, rowIndex: number) => {

                                const isItemSelected = isSelected(pageName === "batch-printing" && row.transactionNumber ? row.transactionNumber : "") //this always tcn
                                /*if (pageName !== "rapback-summary" && pageName !== "batch-printing" && row.agencyORI) isItemSelected = isSelected(row.agencyORI)
                                if (pageName === "rapback-summary" && row.key) isItemSelected = isSelected(row.key); */
                                //if (isItemSelected) console.log(`${pageName} last call after select tcn: ${row.transactionNumber} isItemSelected: `, isItemSelected)

                                //setCurrentRow(row)

                                return (
                                    <TableRow
                                        key={row.key + tableName}
                                        onMouseEnter={pageName !== 'rapback-summary' ? () => setCurrentRow(row.key) : () => { }}
                                        onMouseLeave={pageName !== 'rapback-summary' ? () => setCurrentRow(null) : () => { }}
                                        onClick={(e) => rowClickHandler?.(e, row)}
                                        sx={tableRowStyle}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                    >
                                        {
                                            tableHeaderCells?.map((cell: TableHeaderCell, index: number) => {
                                                //setCurrentValue(row[cell.dataIndex])
                                                //setCurrentDataIndex(cell.dataIndex)
                                                return (
                                                    columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex] && (

                                                        <TableCell
                                                            sx={
                                                                cell.dataIndex === 'actions' || cell.dataIndex === 'checkbox'
                                                                    ? { width: '1%', pl: 0 }
                                                                    : {
                                                                        ...tableBodyCellStyle, width: `${widthPercentage}%`,
                                                                        filter: (openModalBlur || openAgencyEdit) ? 'blur(4px)' : null
                                                                    }
                                                            }
                                                            key={index}>
                                                            <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center" }}>
                                                                {
                                                                    getCellComponentType(row, cell)
                                                                }
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                )
                                            })
                                        }
                                    </TableRow>

                                )
                            }

                        )

            }
        </TableBody>

    )
}