import {
    Typography,
    Button,
    Modal,
    Box,
} from "@mui/material";
import { NotesStyle } from "customStyles/NotesStyle";
import React from "react";
import { DeleteNoteProps } from "@propTypes/index";

export const DeleteModal: React.FC<DeleteNoteProps> = ({ open, setOpen, confirmDeleteNote, selectedNoteId }) => {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={NotesStyle.deleteModalStyle}>
                <Typography variant="h6" component="h3" id="delete-confirmation-modal" sx={{ mb: '0.75rem', fontSiz: '18px', fontFamily: 'Montserrat' }}>
                    ARE YOU SURE YOU WANT TO DELETE?
                </Typography>
                <Typography variant="body1" id="delete-confirmation-description" sx={{ mb: '1.25rem' }}>
                    This action cannot be undone. Select 'Yes, Delete' to continue, or 'No, Cancel' if you would like to return.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '2rem' }}>
                    <Button variant='outlined' sx={{ mr: 2, color: '#430099' }} onClick={() => setOpen(false)}> NO, CANCEL</Button>
                    <Button variant='contained' sx={{ bgcolor: '#430099' }} onClick={() => confirmDeleteNote(selectedNoteId)}>YES, DELETE</Button>
                </Box>
            </Box>
        </Modal>
    )
}