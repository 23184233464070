export enum ColumnStateActions {
    SHOW_ALL_FILTERS = 'SHOW_ALL_FILTERS',
    SHOW_ALL_COLUMNS = 'SHOW_ALL_COLUMNS',
    HIDE_ALL_FILTERS = 'HIDE_ALL_FILTERS',
    HIDE_ALL_COLUMNS = 'HIDE_ALL_COLUMNS',
    RESET_FILTERS = 'RESET_FILTERS',
    RESET_COLUMNS = 'RESET_COLUMNS',
    TOGGLE_FILTER = 'TOGGLE_FILTER',
    TOGGLE_COLUMN = 'TOGGLE_COLUMN'

}