export const TransactionSummaryStyles = {
    createSearchDialog: {
        minWidth: "900px",
        minHeight: "791px",
        backgroundColor: 'background.default'
    },
    createSearchDialogContent: {
        display: "flex",
        flexWrap: "wrap",
        paddingTop: 0,
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "20px",
        height: "662px",
        width: "100%"
    },
    createSearchTextField: {
        width: '418px',
        height: '56px',
        borderColor: "red"
    },
    createSearchSwitchContainer: {
        display: "flex",
        width: "100%",
        height: "24px",
        alignItems: "center"
    },
    createSearchSaveButton: {
        alignSelf: "start",
        width: "138px",
        height: "36px",
        padding: '16px 6px'
    },
    createSearchButtons: {
        width: "94px",
        height: "36px",
        padding: '16px 6px'
    },
    extendModalDialog: {
        minWidth: "600px",
        minHeight: "197px",
        backgroundColor: 'background.default'
    },
    extendModalContent: {
        width: "600px",
        height: "auto",
        padding: '0 24px 20px 24px',
        whiteSpace: "pre-wrap"
    }
};