import { v4 as uuid } from 'uuid'

export const PrintModalHeaderCells = [
    {
        id: uuid(),
        dataIndex: "fullName",
        label: "Full Name",
        alignment: "right",
        numeric: false,
        sortOptions: "ALL"
    },
    {
        id: uuid(),
        dataIndex: "status",
        label: "Status",
        //icon: <FilterListIcon/>,
        alignment: "right",
        numeric: false,

    },
    {
        id: uuid(),
        dataIndex: "transactionDate",
        label: "Transaction Date",
        alignment: "right",
        numeric: false,

    },
    {
        id: uuid(),
        dataIndex: "transactionNumber",
        label: "Transaction Number",
        alignment: "right",
        numeric: false,

    },
]