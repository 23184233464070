/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

//import { ConfigProvider } from "antd";
import React, { useState, useContext, useCallback, useMemo, useEffect } from "react";
//import { darkTheme, lightTheme } from "./modes";
import  darkTheme  from './darkTheme';
import lightTheme  from './lightTheme';
import { ThemeProvider, createTheme, Box, useTheme,  PaletteOptions } from '@mui/material';
import { light } from "@mui/material/styles/createPalette";



declare module '@mui/material/styles' {
  interface Theme {
    
    appBar: {
      iconColor: string;
    }
    
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    
    appBar?: {
      iconColor?: string;
    };
    
  }
}

enum Mode {
  LIGHT = "light",
  DARK = "dark",
}

type ThemeContextType = {
  mode: Mode;
  toggleTheme: () => void;
};


const prefersDarkMode = window?.matchMedia("(prefers-color-scheme: dark)").matches;
const DEFAULT_THEME = prefersDarkMode ? Mode.DARK : Mode.LIGHT;

const ThemeContext = React.createContext<ThemeContextType>({
  mode: DEFAULT_THEME,
  toggleTheme: () => {},
});


const THEMES = {
  light: lightTheme,
  dark: darkTheme
}


const useMUITheme = () => {
  const context = useContext(ThemeContext);
  if (!context) throw new Error("useMUITheme must be used within a ThemeProvider_MUI");
  return context;
};

type ThemeProviderProps = {
  children: React.ReactNode;
};

const ThemeProvider_MUI = ({ children }: ThemeProviderProps) => {
  const [mode, setTheme] = useState<any>(() => {
    const storedTheme: any = localStorage.getItem("theme")
    return THEMES[storedTheme] ? storedTheme : "light";
  })

  useEffect(() => {
    localStorage.setItem("theme", mode);
  }, [mode])

  useEffect(() => {
    if (mode === Mode.DARK) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");    }
  }, [mode]);

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === Mode.LIGHT ? Mode.DARK : Mode.LIGHT));
  }, []);

  const contextValue = useMemo(() => ({ mode, toggleTheme }), [mode, toggleTheme]);

  return (
    <ThemeProvider theme={mode === Mode.DARK ? darkTheme : lightTheme}>
      <ThemeContext.Provider value={contextValue}>
        {children}
     </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export { ThemeProvider_MUI, useMUITheme };
