import { v4 as uuid } from 'uuid'

export const headerCells = [
    {
        id: uuid(),
        dataIndex: "checkbox",
        label: "",
        alignment: "right",
        // textWidth: "1px",
        numeric: false,
        sortOptions: "ALL",
        disablePadding: true
    },
    {
        id: uuid(),
        dataIndex: "firstName",
        label: "First Name",
        alignment: "right",
        textWidth: "70%",
        numeric: false,
        sortOptions: "ALL",
        disablePadding: true
    },
    {
        id: uuid(),
        dataIndex: "lastName",
        label: "Last Name",
        alignment: "right",
        textWidth: "70%",
        numeric: false,
        sortOptions: "ALL",
        disablePadding: true,
    },
    /*{
        id: uuid(),
        dataIndex: "reasonFingerprinted",
        label: "Reason Fingerprinted",
        alignment: "right",
        numeric: false,

    },*/
    {
        id: uuid(),
        dataIndex: "status",
        label: "Status",
        alignment: "right",
        disablePadding: true,
        numeric: false,

    },
    {
        id: uuid(),
        dataIndex: "transactionDate",
        label: "Transaction Date",
        alignment: "right",
        disablePadding: true,
        numeric: false,

    },
    {
        id: uuid(),
        dataIndex: "transactionNumber",
        label: "Transaction Number",
        textWidth: "110px",
        alignment: "right",
        disablePadding: true,
        numeric: false,

    },
    {
        id: uuid(),
        dataIndex: "ori",
        label: "ORI",
        alignment: "right",
        disablePadding: true,
        numeric: false,

    }
]