/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */
import { UserPermission } from "../../customEnums/index";

const adminConfig = {
  agencyManagement: [
    UserPermission.viewAgencies,
    UserPermission.addNewAgencies,
    UserPermission.editExistingAgencies,
    UserPermission.deleteExistingAgencies,
  ],
  detailsTab: [
    UserPermission.viewAllDetailsData,
    UserPermission.viewAuditHistory
  ],
  manualAdjudication: [
    UserPermission.allowManualAdjudication
  ],
  notesTab: [
    UserPermission.createNewNotes,
    UserPermission.editOwnNotes,
    UserPermission.printNotes,
    UserPermission.viewOthersNotes,
    UserPermission.editOthersNotes,
  ],
  resultsTabOriginal: [
    UserPermission.viewOriginalResults,
    UserPermission.printOriginalResults,
  ],
  resultsTabRedaction: [
    UserPermission.viewRedactedResults,
    UserPermission.printRedactedResults,
    UserPermission.editRedactedResults,
    UserPermission.createNewRedactedResults,
  ],
  transactionSummary: [
    UserPermission.viewTransactionSummary,
    UserPermission.sortTransactionSummary,
    UserPermission.saveTransactionSummarySearches,
    UserPermission.canResubmitTransaction,
    UserPermission.extendTransactionRecord,
    UserPermission.viewAllTransactionsNoLimit,
    UserPermission.viewAdvancedSearchExtend,
    UserPermission.triggerViewedRecord,

  ],
  rapBackSummary: [
    UserPermission.viewRapBack,
    UserPermission.viewRapBackDetails,
    UserPermission.viewFbiRapback,
    UserPermission.printRapsheetPDF,
    UserPermission.viewRapsheetPDF,
    UserPermission.triggerViewedRecord,
  ],
  rapbackSubscriptions: [
    UserPermission.viewRapbackSubscriptions
  ],
  viewAdministration: [
    UserPermission.viewAdministration,
    UserPermission.viewUserPermissions,
    UserPermission.viewUserPreferences,
    UserPermission.viewReporting,
    UserPermission.viewUserManagement
  ],
  batchPrinting: [
    UserPermission.viewBatchPrinting
  ]
};

export const test = {
  viewAgencies: true,
  addNewAgencies: true,
  editExistingAgencies: false,
  deleteExistingAgencies: true,
  viewAllData: true,
  allowManualAdjudication: false,
  createNew: true,
  editOwn: true,
  print: false,
  viewOthers: true,
  editOthers: true,
  viewOriginal: true,
  printOriginal: false,
  viewRedacted: true,
  printRedacted: true,
  editRedacted: true,
  createNewRedacted: true,
  view: true,
  searchAndSort: true,
  saveSearches: true,
  sortTransactionSummary: true,
  saveTransactionSummarySearches: true,
  canResubmitTransaction:true,
  viewAdministration: true,
  viewAdvancedSearchExtend: true,
  viewRapbackSubscriptions: true,
  viewReporting: true,
  viewAuditHistory: true,
  viewUserManagement: true,
  viewUserPermissions: true,
  viewUserPreferences: true,
  triggerViewedRecord: true,
};

const allPermissions = Object.values(adminConfig).flat();

export const formatPermissionsAfterFetch = (permissions: any) => {
  //console.log("debug users permissionss permissions", permissions);
  const currentPermissions = permissions[0].data.map(({ id }: any) => id);
  //console.log("debug users permissions", currentPermissions);
  const formattedPermissions = {} as any;
  //console.log('debug permissions allPermisisons: ', allPermissions)
  allPermissions.forEach((permission) => {
    formattedPermissions[permission] = currentPermissions.includes(permission);

  });
  return formattedPermissions;
};

const invertPermissions = (permissions: { [key: string]: string[] }) =>
  Object.entries(permissions).reduce((acc, [key, values]) => {
    values.forEach((value) => {
      acc[value] = key;
    });
    return acc;
  }, {} as { [key: string]: string });

export function formatPermissionsForForm(obj: Record<string, boolean>) {
  if (!obj) return {};
  const keyMappings = invertPermissions(adminConfig);

  return Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      const newKey = keyMappings[key];
      //@ts-ignore
      if (value && newKey) newObj[newKey].push(key);
      return newObj;
    },
    Object.keys(adminConfig).reduce(
      (acc, key) => ({ ...acc, [key]: [] }),
      {} as Record<string, string[]>
    )
  );
}
