/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react'
import { useUserRole } from './useUserRole';
import { urls } from '../urls'
import { generateRapBackQueryParams, transformRapBackData } from 'utils/queryTransactionsHelpers';
import { QueryRequestBody } from 'interfaces/common/QueryRequestBody';
import { useTableStore, useUserStore } from "../state";

export const useQueryRapBack = (
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    searchParams: any,
    userORIs,
    userRole,
    pageName = "rapback-summary"
) => {

    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const columnState = useTableStore(state => state.columnState)
    const userConfig = useUserStore(state => state.userConfig)
    const tableConfig = useTableStore(state => state.tableConfig)

    const url = urls.QUERY

    useEffect(() => {
        const controller = new AbortController()

        let filters = columnState.page["rapback-summary"]["rapback-summary"]?.filters
        let hasFilters = tableConfig["rapback-summary"]?.filter
        const filterLabels: string[] = []

        if (hasFilters) {
            filters.forEach((filter) => {
                let filterValueKeys = Object.keys(filter.filterValues)
                filterValueKeys.forEach((key) => {
                    console.log('key: ', key)
                    if (filter.filterValues[key].selected === true) {
                        filterLabels.push(filter.filterValues[key].label)
                    }
                })
            })
        }

        console.log(`debug multi table pagination useQueryRapback useEffect dependencies fetchData conditionals: ${JSON.stringify({
            userORIs,
            userRole,
            filterLabels,
            columnStateRbSummary: columnState.page["rapback-summary"],
            runSearch: userORIs && userORIs.length && userRole && filterLabels.length > 0
        }, null, 2)}`)

        console.log(`10101010101 debug userConfig in query: `, userConfig)


        if (userORIs && userORIs.length && userRole) {

            if (pageName != "transaction-summary") console.log('101010110 debug useQuery useQueryRapback useEffect dependencies RUNNING FETCH')

            const adminRoles = ["mc_admin"];
            const isAdmin = adminRoles.includes(userRole);
            console.log('useQueryRapback TableFilter Data: ', JSON.stringify(searchParams));

            const queryParams = generateRapBackQueryParams(filterLabels, isAdmin, userORIs, orderBy, order, searchParams)

            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_RAPBACK_SUMMARY_TABLE_QUERY!
                    }
                ]
            }

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_RAPBACK_SUMMARY_TABLE_COUNT_QUERY!
                    }
                ]
            }

            const fetchRapBacks = async (queryDataRequest: any, queryCountRequest: any) => {
                try {
                    const rapBackUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

                    let i: number = 1;
                    setLoading(true)

                    const data = await (await fetch(rapBackUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(queryDataRequest)
                    })).json();

                    const countData = await (await fetch(rapBackUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(queryCountRequest)
                    })).json();

                    const { results } = data['queriesResults'][0];
                    const count = countData['queriesResults'][0].results[0]['count'];
                    const transformedData = results.map((obj: any, index: number) => transformRapBackData(obj, index));
                    setMappedData(transformedData);
                    setTotalRecords(count);
                    setLoading(false);

                } catch (err: any) {
                    setError(err);
                    setLoading(false);
                }
            }


            console.log('debug pagination useQueryRapBack dependencies: ', {
                userRole,
                userORIsLength: userORIs.length,
                rowsPerPage: pageSize,
                page: currentPage,
                order,
                orderBy,
                searchParamsLength: searchParams.length,
                filterLabels
            })
            if (pageName != "transaction-summary") fetchRapBacks(queryDataRequest, queryCountRequest)
        }


        return () => {
            controller.abort();
        }
    }, [
        userRole,
        userORIs.length,
        pageSize,
        currentPage,
        order,
        orderBy,
        searchParams,
        searchParams.length,
        columnState,
        columnState.page["rapback-summary"]!.filters?.length])

    const handleResetToNotViewed = async (e, row) => {
        console.log('handleReset row: ', row)
        let id = row.key
        console.log(`debug updateRecord running ${JSON.stringify({ id }, null, 2)}`)
        const updateRecord: string = `${process.env.NODE_ENV === "production" ? urls.REPORTING : ""}/last-viewed`
        const body = {
            "id": id,

        }

        console.log('updateRecord called with body: ', JSON.stringify(body, null, 2));

        let resp;
        try {
            resp = await (await fetch(updateRecord, {
                method: "DELETE",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('updateRecord resp: ', resp)
        } catch (e) {
            console.log('Error Updating Record ', resp)
        }
        return resp
    }


    const upsertViewedRecord = async (id: string, user: string) => {
        console.log(`debug updateRecord running ${JSON.stringify({ id, user }, null, 2)}`)
        const updateRecord: string = `${process.env.NODE_ENV === "production" ? urls.REPORTING : ""}/last-viewed`
        const body = {
            "id": id,
            "modified_user": user
        }

        console.log('updateRecord called with body: ', JSON.stringify(body, null, 2));

        let resp;
        try {
            resp = await (await fetch(updateRecord, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('updateRecord resp: ', resp)
        } catch (e) {
            console.log('Error Updating Record ', resp)
        }
        return resp
    }



    return { data: mappedData, totalRecords, countError, loading, upsertViewedRecord, handleResetToNotViewed }
}