interface SelectAll {
    page: number;
    selectAll: boolean;
}

const getNewState = (pageExists: boolean, pages: [SelectAll], page: number, value: boolean) => {
    console.log('select all getNewState pageExists: ', pageExists)
    console.log('select all getNewState pages: ', pages)
    if(pageExists) {
        pages.forEach((element: any) => {
            if(element.page === page) {
              element.selectAll = value
            }
          })
    } else {
        pages.push({page, selectAll: value})
    }

    console.log('select all pagination getNewState returning: ', ...pages)

    return {
       pages: pages
    }
}
export const selectAllReducer = (state: any, action: any): any => {
    const { pages } = state
    const pageExists = pages.some((element) => element.page === action.page)
    const page = action.page

    switch(action.type) {
        case "SELECT_ALL":

            return getNewState(pageExists, pages, page, true)

            

        case "DESELECT_ALL":
            

            return getNewState(pageExists, pages, page, false)
    }
}