import { urls } from '../../../urls';

export async function sendPreAuthAudit(userName: string, activityAction: string, activityDetails: string ) {

    try {
        let auditEvent = {
            operatorName: userName,
            activityAction: activityAction,
            activityApplication: "CARES Application",
            activityDetails: activityDetails,
            activityStartTime: new Date()
        }

        let currentConfig = { preauth: "No user yet" }

        const auditBody = {
            audit: auditEvent,
            currentConfig
        }

        console.log('preauth audit sendAuditEventToSqs auditBody: ', auditBody)

        await fetch(`${process.env.NODE_ENV === 'production' ? urls.AUDIT : ""}/audit`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(auditBody)
        });
    } catch (err) {
        console.error(`Failed to send preauth audit ${err}`)
    }
}