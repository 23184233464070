import dayjs from "dayjs";
import { extractPageName } from "../common/CustomTable/helpers";
let utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const getValue = (value: string) => {
    if (value === "") {
        return "null"
    } else return value
}

const mapCHRISearchTerm = (searchTerm: string) => {
    let mapY = searchTerm.toLowerCase() === "found" || searchTerm.toLowerCase() === "record found"
    let mapN = searchTerm.toLowerCase().includes("no")
    let mapNull = searchTerm.toLowerCase().includes("pending") || searchTerm.toLowerCase().includes("results")
    let mapNS = searchTerm.toLowerCase().includes("name") || searchTerm.toLowerCase().includes("search")

    if (mapY) return "recordFound"
    if (mapN) return "noRecordFound"
    if (mapNull) return "resultsPending"
    if (mapNS) return "nameSearch"
}


export const getAdvancedParamValues = (column: any, searchParams: any, softLimit: string = "null", pageName: any = undefined, dateBound: any = undefined) => {
    console.log(`debug getParamValues searchParams ${JSON.stringify(searchParams, null, 2)}, column: ${column}, dateBound: ${dateBound}, pageName: ${pageName}`);

    if (searchParams.find(((param: any) => param.column === column))) {
        console.log(`debug getParamValues  ${column} found`)
        if (!dateBound) {
            let value = getValue(searchParams.find((param) => param.column === column).value)
            if (column === "upperDate") {
                return dayjs(value).endOf("day").utc().format(); // "2023-12-30T01:29:36.276981+00:00"

            } else if (column === "lowerDate") {
                return dayjs(value).startOf('day').utc().format(); // "2023-12-30T01:18:38.601648+00:00" 

            } else {
                if (column === "hitNoHit") return mapCHRISearchTerm(value)
                else return value.trim()
            }
        } else {
            //simple search date
            const date: string = searchParams.find((param) => param.column === column).value
            console.log('debug getParamValues date: ', date)
            if (!date.includes("-")) {
                console.log('custom table search date is singular date')
                if (dateBound === "lowerDate") {
                    return dayjs(date).startOf("day").utc().format()
                } else {
                    return dayjs(date).endOf("day").utc().format()
                }
            } else {
                console.log('custom table search date is range')
                let parseWithSpace = date.includes(" - ")
                let parsedDate = date.split(parseWithSpace ? " - " : "-");
                let lowerDate = parsedDate[0]
                let upperDate = parsedDate[1]
                console.log('custom table search parsedDate: ', parsedDate)
                if (dateBound === "lowerDate") {
                    return dayjs(lowerDate).startOf('day').utc().format();
                } else {
                    return dayjs(upperDate).endOf("day").utc().format();
                }
            }

        }

    } else {
        if (column === "days") {
            console.log('debug extend days: ', { pageName, softLimit })
            return pageName
                ? pageName === "rapback-maintenance"
                    ? "null"
                    : softLimit
                : softLimit
        }
        return 'null'
    }
}

export const getBatchPrintParamValues = () => {

}

export const getBasicParamValues = (column: any, searchParams) => {
    if (searchParams.find((param: any) => param.column === column)) {
        let value = searchParams.find((param) => param.column === column).value
        return value;
    } else {
        return 'null';
    }
}

export const getRapBackParamValues = (column: any, searchParams, dateBound: any = undefined) => {
    if (searchParams.find((param: any) => param.column === column)) {
        if (!dateBound) {
            // when it's not a date search
            let value = searchParams.find((param) => param.column === column).value
            if (column === 'isHit') {
                if (value.toLowerCase() === 'no hit') {
                    return 'n'
                } else if (value.toLowerCase() === 'hit') {
                    return 'y'
                } else if (value.toLowerCase() === 'ERRT-Error') {
                    return 'errt'
                }
            } else {
                return value;
            }
        } else if (column === 'dob') {
            return dayjs(searchParams.find((param) => param.column === column).value).format("MMDDYYYY");
        } else {
            const date: string = searchParams.find((param) => param.column === column).value
            if (!date.includes("-")) {
                console.log("Custom table search date is singular date")
                if (dateBound === "lowerDate") {
                    console.log('dayjs(lowerDate).format(YYYYMMDD);', dayjs(date).format('YYYYY-MM-DDTHH:mm:ss.SSSSSZ'))

                    return dayjs(date).startOf('day').format('YYYYMMDD');
                } else {
                    // return dayjs(date).add(1, "day").format('YYYYMMDD')
                    return dayjs(date).endOf("day").format('YYYYMMDD');
                }
            } else {
                console.log('Custom table search date is a range');
                let parseWithSpace = date.includes(" - ")
                let parsedDate = date.split(parseWithSpace ? " - " : "-");
                let lowerDate = parsedDate[0]
                let upperDate = parsedDate[1]
                console.log('custom table search parsedDate: ', parsedDate)
                if (dateBound === "lowerDate") {
                    return dayjs(lowerDate).startOf('day').format('YYYYMMDD');
                } else {
                    return dayjs(upperDate).endOf("day").format('YYYYMMDD');
                }
            }
        }
    } else {
        return 'null'
    }
}