export const queryParams = [
    {
        "value": "true",
        "name": "isAdmin"
    },
    {
        "name": "ori_rfp_json",
        "value": "[{\"ori\":\"ALL\",\"rfp\":[\"ALL\"]}]"
    },
    {
        "name": "sori_rfp_json",
        "value": "[{\"sori\":\"ALL\",\"rfp\":[\"ALL\"]}]"
    },
    {
        "value": "null",
        "name": "days"
    },
    {
        "value": "transactionDate",
        "name": "orderBy"
    },
    {
        "value": "desc",
        "name": "order"
    },
    {
        "value": "null",
        "name": "fullName"
    },
    {
        "value": "null",
        "name": "firstName"
    },
    {
        "value": "null",
        "name": "lastName"
    },
    {
        "value": "null",
        "name": "ssn"
    },
    {
        "value": "null",
        "name": "lsTcn"
    },
    {
        "value": "null",
        "name": "afisTcn"
    },
    {
        "value": "null",
        "name": "ori"
    },
    {
        "value": "null",
        "name": "secondaryOri"
    },
    {
        "value": "[\"Active\",\"Subscribed\",\"Edit Pending\",\"Pre-Notification Pending\",\"Validation Pending\",\"Unsubscribe Pending\"]",
        "name": "selectedStatus"
    },
    {
        "value": "null",
        "name": "status"
    },
    {
        "value": "null",
        "name": "isPending"
    },
    {
        "value": "null",
        "name": "isActive"
    },
    {
        "value": "null",
        "name": "expiryDaysLimit"
    },
    {
        "value": "null",
        "name": "needsConfirm"
    },
    {
        "value": "null",
        "name": "rapbackId"
    },
    {
        "value": "null",
        "name": "lowerDobDate"
    },
    {
        "value": "null",
        "name": "upperDobDate"
    },
    {
        "value": "null",
        "name": "lowerFingerPrintDate"
    },
    {
        "value": "null",
        "name": "upperFingerPrintDate"
    },
    {
        "value": "null",
        "name": "lowerExpirationDate"
    },
    {
        "value": "null",
        "name": "upperExpirationDate"
    },
    {
        "value": "null",
        "name": "lowerSubscriptionDate"
    },
    {
        "value": "null",
        "name": "upperSubscriptionDate"
    },
    {
        "value": "null",
        "name": "lowerNeedsConfirmDate"
    },
    {
        "value": "null",
        "name": "upperNeedsConfirmDate"
    }
]