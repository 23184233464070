export enum CellType {
    SIMPLE = "Simple",
    MULTI = "Multi",
    BUTTON = "Button",
    ACTIONS = "Actions",
    SHOWHIDE = "ShowHide",
    CHECKBOX = "Checkbox",
    CUSTOM_TABLE_SELECT = "CustomTableCellSelect",
    CUSTOM_CHIP = "CustomChip"

}