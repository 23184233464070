import React, { useEffect, useState } from "react"
import { Button, Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material'
import { ExtendProps } from '@propTypes/index';
import { TransactionSummaryStyles } from "../../../customStyles/index";
import { useTableStore, useUserStore } from "state";
import dayjs from "dayjs";
import { Config } from "interfaces/config";
import { stat } from "fs";

var minMax = require("dayjs/plugin/minMax");
dayjs.extend(minMax);
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore)
var relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)
var duration = require("dayjs/plugin/duration")
dayjs.extend(duration)




export const ExtendModal: React.FC<ExtendProps> = ({ row, open, handleClose, handleExtend, data }) => {

  const config = useUserStore(state => state.config)
  const { hardLimit, softLimit, extendDays, maxExtensions } = config.transactionViewing;
  const applicantName = useTableStore(state => state.applicantName);
  const transactionDate = useTableStore(state => state.transactionDate)
  const transactionExpireDate = row.expireDate
  const [extendModalText, setExtendModalText] = useState("")


  const [date, setDate] = useState<any>()



  useEffect(() => {

    let hardLimitDate = dayjs(transactionDate).add(hardLimit, 'days').format('LL')
    let extendedFromClickDate = dayjs().add(extendDays, 'days').format('LL')
    let extendWithinSoftLimitDate = dayjs.max(dayjs(), dayjs(transactionExpireDate).add(extendDays, 'days'), dayjs().add(extendDays, 'days'))!.format("LL")

    let daysSinceTransactionDate = dayjs().diff(transactionDate, 'days')

    console.log('debug extend extendModal dates: ', {
      hardLimitDate,
      extendedFromClickDate,
      originalExpireDate: dayjs(transactionExpireDate).format('LL'),
      expireDatePlusExtend: dayjs(transactionExpireDate).add(extendDays, 'days').format('LL'),
      extendWithinSoftLimitDate,
      daysSinceTransactionDate
    })

    const createExtendTextWithinSoftLimit = (date) => {
      setExtendModalText(
        applicantName + "'s record is available until " + dayjs(transactionExpireDate).format('LL') + ". " + "By extending the record, you will enable access for an additional " + extendDays + " days after the original expiration date. The new expiration date for this record will be " + dayjs(date).format('LL') + ". " + "\n\n" + (maxExtensions === 1 && 'Records can only be extended once. Are you sure you would like to continue?'
        ))
    }

    const createExtendTextOutsideSoftLimit = (date) => {
      setExtendModalText(
        "By extending the record, you will enable access for an additional " + extendDays + " days. The new expiration date for this record will be " + dayjs(date).format('LL') + "."
      )
    }

    const extendNearHardLimit = (date) => {
      setExtendModalText(
        "This record can only be extended through " + dayjs(date).format('LL') + ". " + "The new expiration date for this record will be " + dayjs(date).format('LL') + ". " + "Are you sure you would like to continue?"
      )
    }

    //Fix Pagination last 26 on transaction page resetting and searching again to get 100 on page change
    if (daysSinceTransactionDate <= softLimit) {
      //Extend within soft limit date
      if (dayjs(extendWithinSoftLimitDate).isSameOrBefore(hardLimitDate)) { //Check for hardLimit date
        setDate(extendWithinSoftLimitDate) //If within hardLimit set to extendWithinSoftLimitDate aka larger of transactiondate + softLimit + extenDays && currentDate + extendDays 
        createExtendTextWithinSoftLimit(extendWithinSoftLimitDate)
      } else {
        setDate(hardLimitDate) //If extendWithinSoftLimitDate aka transactiondate + softLimit + extenDays && currentDate + extendDays set to hardLimitDate
        extendNearHardLimit(hardLimitDate)
      }

    } else {
      //Extend outside soft limit date
      if (dayjs(extendedFromClickDate).isSameOrBefore(hardLimitDate)) { //Check for hardLimit date
        setDate(extendedFromClickDate) //If outside softLimit within hardLimit set to extendFromClickDate aka currentDate + extendDays
        createExtendTextOutsideSoftLimit(extendedFromClickDate)
      } else {
        setDate(hardLimitDate)
        extendNearHardLimit(hardLimitDate)//If extendFromClickDate aka currentDate + extendDays is beyond hardLimitDate set to hardLimitDate
      }
    }

  }, [hardLimit, softLimit, extendDays, transactionDate])

  const getNumExtendedDays = (timeA, timeB) => {
    const ta = dayjs(timeA, 'HH:mm');
    let tb = dayjs(timeB, 'HH:mm');

    if (ta.isAfter(tb)) tb = tb.add(1, 'd');

    const diff = dayjs(tb).diff(ta, 'hours');

    let days = Math.ceil(dayjs.duration(diff, 'hours').asDays())

    return days
  }



  return (
    <Dialog open={open} fullWidth={true} PaperProps={{ sx: TransactionSummaryStyles.extendModalDialog }}>
      <DialogTitle>Extend Access to Applicant?</DialogTitle>
      <DialogContent sx={TransactionSummaryStyles.extendModalContent}>
        <Typography >
          {extendModalText}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
        <Button onClick={() => handleClose()} variant="outlined">CANCEL</Button>
        <Button onClick={() => handleExtend(dayjs(date).format('YYYY-MM-DD'), getNumExtendedDays(dayjs(), dayjs(date)))} variant="contained">Yes, Extend</Button>
      </DialogActions>
    </Dialog>
  )
}