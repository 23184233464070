export enum TableNames {
    AUDIT_HISTORY = "audit-history",
    STATUS_HISTORY = "status-history",
    ADJUDICATION_DOCS = "adjudication-docs",
    OUTGOING_TRANSMISSION = "outgoing-transmission",
    INCOMING_RESPONSE = "incoming-response",
    REJECT_CODES = "reject-codes",
    TRANSACTION_SUMMARY = "transaction-summary",
    RB_SUMMARY_AUDIT_HISTORY = "rb-summary-audit-history",
    RB_SUB_AUDIT_HISTORY = "rb-sub-audit-history",
    RAPBACK_SUMMARY = "rapback-summary",
    RAPBACK_MAINTENANCE = "rapback-maintenance",
    RAPBACK_PRENOTIFICATIONS = "rapback-prenotifications",
    RAPBACK_VALIDATIONS = "rapback-validations",
    USER_MANAGEMENT = "user-management",
    AGENCY_MANAGEMENT = "agency-management",
    BATCH_PRINTING = "batch-printing"
}