import { CustomTable } from 'common/CustomTable/CustomTable';
import {
  Paper,
  Drawer,
  Box,
  Divider,
  Typography,
  Button,
  Grid,
  Container,
  LinearProgress
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import PrinterIcon from '@mui/icons-material/LocalPrintshop';
import { CustomTableTitleAndAlert, RightDrawer } from '../../common'
import { useExpanded } from 'components/layouts/ProtectedLayout';
import React, { useCallback, useState, useEffect, useRef, useMemo, useReducer } from 'react'
import { mockRapXML } from '../ApplicantDetails/components/ApplicantDetailsBody/Results/CustomEditor/mockRapXML';
import { useQueryRapBack } from "hooks/useQueryRapBack"
import printText from "utils/printText";
import { useAudit } from "hooks/useAudit";
import { Auth } from 'aws-amplify';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Order } from '../../customTypes/index';
import { RapbackSummaryStyles } from '../../customStyles/index';
import { pageStyles } from 'customStyles/common';
import { ColumnState, useTableStore } from 'state/TableStore';
import { extractPageName } from "../../common/CustomTable/helpers";
import { useLocation } from "react-router-dom";
import { States } from "interfaces/common";
import { formatDate, getCurrentEnv } from 'utils';
import { AuditHistoryTable } from 'common/AuditHistoryTable';
import { useConfigService, useRapbackPdfURL, useSetConfig } from '../../hooks';
import { ReactNode } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { printPlugin } from '@react-pdf-viewer/print';
import { generateTableConfig } from 'utils';
import { useUserStore } from 'state';
import { TableNames } from 'customEnums/TableNames';
import { savedSearchesReducer } from 'pages/TransactionSummary/helpers/savedSearchesReducer';

export const RapBackSummary: React.FC = () => {
  const STATE = getCurrentEnv
  const { expanded } = useExpanded();
  const [sortOrder, setSortOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('insertdate');
  const [displaySSN, setDisplaySSN] = useState('')
  const [fullName, setFullName] = useState('');
  const [showSSN, setShowSSN] = useState<boolean>(false);
  const [details, setDetails] = useState<string>("")
  const [sidCurrent, setSid] = useState<string>("")
  const [openRapbackDrawer, setOpenRapbackDrawer] = useState<boolean>(false);
  const { addAuditEvent } = useAudit();
  const [searched, setSearched] = useState<boolean>(false);
  const [value, setValue] = React.useState<any>([]);
  console.log('debug userStore setting userConfig')
  const userConfig = useUserStore(state => state.userConfig)
  const { userORIs, userRole, usersPermissions } = userConfig;
  const [currentKey, setCurrentKey] = useState("")
  const [data, setData] = useState<[]>([]);
  const [openAuditDrawer, setOpenAuditDrawer] = useState(false)
  const [id, setId] = useState("");
  const [applicantName, setApplicantName] = useState("")
  const printPluginInstance = printPlugin();
  const { print } = printPluginInstance;

  const searchParams = useTableStore((state) => state.searchParams)
  const pagination = useTableStore((state) => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)
  const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
  const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
  const openNotification = useTableStore(state => state.openNotification)
  const setOpenNotification = useTableStore(state => state.setOpenNotification)
  const simpleSearchRan = useTableStore(state => state.simpleSearchRan)
  const setActionsConfig = useTableStore(state => state.setActionsConfig)
  //const setOpenAuditHistory = useTableStore(state => state.setOpenAuditHistory)

  const { rapbackPdfURL, loadingPDF } = useRapbackPdfURL(currentKey as string, openRapbackDrawer);
  const locationObj = useLocation();
  const pageName = extractPageName(locationObj.pathname);
  const setTableConfig = useTableStore(state => state.setTableConfig)
  const tableConfig = useTableStore(state => state.tableConfig)
  const config = useUserStore(state => state.config)
  const isPost = useTableStore((state) => state.isPost)
  const isDelete = useTableStore((state) => state.isDelete)
  const configFile = useTableStore((state) => state.configFile)
  const configBody = useTableStore((state) => state.configBody)
  const { searchData } = useConfigService(configFile, configBody, true, isPost, isDelete);
  const loadedData = useMemo(() => ({ ...searchData }), [searchData]);
  const [savedSearches, dispatchSearch] = useReducer(savedSearchesReducer, loadedData);
  const setColumnState = useTableStore(state => state.setColumnState)
  const columnState: ColumnState = useTableStore(state => state.columnState)

  const handleOpenAuditHistory = async (e, row) => {
    console.log('debug Rapback summary mappedData - Audit History row: ', row)
    setOpenAuditDrawer(true);
    setId(row.key)
    setApplicantName(`${row.firstName + " " + row.lastName}`)
  }

  const actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler: Function }[] = [
    usersPermissions.viewRapBackDetails && {
      actionLabel: "View Details",
      actionHandler: (e, row) => {
        e.stopPropagation()
        viewRapBack(e, row)
      }
    },
    {
      actionLabel: "Record History / Notifications",
      actionHandler: handleOpenAuditHistory
    }
  ].filter(Boolean)

  const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.RAPBACK_SUMMARY, {}, actionsConfig)

  const {
    data: mappedData,
    totalRecords,
    loading, upsertViewedRecord }: any = useQueryRapBack(rowsPerPage, page, sortOrder, orderBy, searchParams, userORIs, userRole)

  useEffect(() => {
    setPagination({ ...pagination, [TableNames.RAPBACK_SUMMARY]: { page: 1, rowsPerPage: 25 } })
  }, [])

  useEffect(() => {
    console.log('debug customTableHead rapback mappedData: ', mappedData)
    setData(
      mappedData?.map((data: any) => {
        return {
          key: data.key,
          agency: data.agency,
          fileData: data.fileData,
          firstName: data.firstName,
          insertDate: data.insertDate,
          isHit: handleResponseType(data.isHit),
          lastName: data.lastName,
          middleName: data.middleName,
          ...STATE === States.GA ? { primaryOri: data.primaryOri, secondaryOri: data.secondaryOri } : { oris: data.oris },
          rapbackdate: data.rapbackdate,
          reason: data.reason,
          sid: data.sid,
          ssn: data.ssn,
          view: data.view,
          viewed: data.viewed,
          viewedDate: data.viewedDate,
          viewedUser: data.viewedUser
        };
      })
    );
  }, [mappedData?.length, mappedData])

  const handleClickShowSSN = useCallback(async (record: string, e: any) => {
    if (displaySSN !== record) {
      setDisplaySSN(record)
      setShowSSN(!showSSN)
    } else {
      setDisplaySSN('')
      setShowSSN(!showSSN)
    }
  }, [displaySSN])


  const viewRapBack = useCallback(async (e, row: any) => {
    console.log('viewRapback text', row)
    let key = row.key
    const { username } = await Auth.currentAuthenticatedUser()
    console.log('rapBackSummary rapBack: ', mappedData)
    const details = mappedData.find((obj: any) => obj.key === key).fileData
    const sidCurrent = mappedData.find((obj: any) => obj.key === key).sid
    const firstName = mappedData.find((obj: any) => obj.key === key).firstName
    const middleName = mappedData.find((obj: any) => obj.key === key).middleName
    const lastName = mappedData.find((obj: any) => obj.key === key).lastName

    // update record in the backend once rapback is viewed - should utilize userPermissions in the future
    if (usersPermissions.triggerViewedRecord) {
      const resp = await upsertViewedRecord(row.key, username);
      if (resp.code === 200) {
        setData((prevData: any) => {
          return prevData.map((data: any) => {
            if (data.key === key) {
              return {
                ...data,
                viewed: true,
                viewedUser: username,
                viewedDate: formatDate(new Date().toISOString())
              }
            }
            return data
          })
        })
      }
    }

    setFullName(`${firstName} ${middleName} ${lastName} `)
    setDetails(details)
    setSid(sidCurrent)
    console.log('click event rapback viewRapBack text: ', key)
    addAuditEvent("Viewed", `Viewed Rapback Details`, new Date(), key)
    setOpenRapbackDrawer(true)
    setCurrentKey(key)
  }, [mappedData?.length])

  const closeDrawer = () => {
    console.log('debug multi table pagination insideCloseDrawer')
    setOpenRapbackDrawer(false)
    setOpenAuditDrawer(false)
    setCurrentKey("")
    setPagination({
      ...pagination,
      [TableNames.RB_SUMMARY_AUDIT_HISTORY]: {
        page: 0,
        rowsPerPage: 5,
        sortOrder: "desc",
        orderBy: ""
      }
    })
  }

  const createMarkup = (details: string) => {
    console.log('rapBackSummary createMarkup rapBacks: ', mappedData)
    if (mappedData)
      return { __html: details || mappedData.fileData };
  }

  const printRapbackDetails = () => {
    printText(details ?? "", "", "", {
      printBackground: true,
    })
    addAuditEvent("Printed Report ", "Print data for SID: " + sidCurrent, new Date(), currentKey)
  };

  const printRapsheetPDF = () => {
    print()
    addAuditEvent("Printed Report ", "Print data for SID: " + sidCurrent, new Date(), currentKey)
  }

  const handleResponseType = (type: string) => {
    if (type.toLowerCase() === 'n') {
      return 'No Hit'
    }
    if (type.toLowerCase() === 'y') {
      return 'Hit'
    }
    if (type.toLowerCase() === 'errt') {
      return 'ERRT-Error'
    }
    if (type.toLowerCase() === "n/a") {
      return "N/A"
    }
  }

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleRequestSort = (event: any, property) => {
    if (property === "reasonFingerPrinted") {
      property = "rfp"
    } else if (property === "oris") {
      property = "ori"
    }
    console.log(`sort date handleReuqestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
    const isAsc = orderBy === property && sortOrder === 'asc';
    console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
    setSortOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property);
  };

  useEffect(() => {
    console.log('rapback summary mappedData?.length simpleSearchRan', simpleSearchRan)
    console.log('rapback summary mappedData?.length', mappedData?.length)
    if (mappedData?.length === 0 && simpleSearchRan) {
      setDisplayNoSearchResults(true)
      setOpenNotification(true)
    }
    console.log('in rapbacksummary debug open notification display no seasrch', displayNoSearchResults)
    console.log('in rapbacksummary debug open notification', openNotification)

  }, [mappedData?.length, simpleSearchRan])

  useEffect(() => {
    if (displayNoSearchResults) {
      setTimeout(() => {
        setDisplayNoSearchResults(false)
        setOpenNotification(false)
      }, 10000)
    }
  }, [displayNoSearchResults])

  useEffect(() => {
    //setOpenAuditHistory(handleOpenAuditHistory)

    // useEffect for adapting to different screen size
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mappedData])

  return (
    <>
      {
        usersPermissions.viewRapBack && (
          <Paper sx={{ ...pageStyles.pagePaperStyle, minWidth: `calc(100vw - ${expanded ? "256px" : "64px"})` }} elevation={0} >
            <CustomTableTitleAndAlert name="Rapback Summary" />
            <RightDrawer openDrawer={openAuditDrawer} closeDrawer={closeDrawer}>
              <Container sx={{ marginBottom: "18px" }} disableGutters>
                <Typography sx={{ fontSize: '1.5rem' }}>{applicantName} Audit History</Typography>
              </Container>
              <AuditHistoryTable id={id} name={TableNames.RB_SUMMARY_AUDIT_HISTORY} />
            </RightDrawer>
            <Drawer anchor='right' open={openRapbackDrawer} onClose={closeDrawer} sx={{ zIndex: 100000 }}>
              <Box sx={RapbackSummaryStyles.rapbackDrawerBox}>
                <Grid container justifyContent='flex-end' alignItems='center' sx={{ pb: '2rem', position: 'sticky', top: 0 }}>
                  <Button size='small' startIcon={<CloseIcon />} sx={{ color: 'text.primary' }} onClick={closeDrawer}>CLOSE</Button>
                </Grid>
                <Grid container display='block' flexDirection='column' justifyContent='center' flexGrow={1}>
                  <Typography sx={{ fontSize: '1.5rem' }}>{fullName} - RAPBACK DETAILS</Typography>
                  <Divider sx={{ mt: '1.5rem', mb: '1.5rem' }} />
                  <SimpleBar style={{ flex: 1, overflowY: 'auto', height: screenHeight < 1000 ? '65vh' : '80vh' }}>
                    {loadingPDF ? <LinearProgress color='primary' />
                      : usersPermissions.viewRapsheetPDF ? rapbackPdfURL.length > 0 ?
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                          <Viewer fileUrl={rapbackPdfURL?.[0]} plugins={[printPluginInstance]} />
                        </Worker> : 'No PDF Available At This Time' :
                        <div dangerouslySetInnerHTML={createMarkup(details)} style={{ fontFamily: 'Montserrat', whiteSpace: "pre-line" }} />
                    }
                  </SimpleBar>
                  <Grid xs={12} item={true} sx={{ display: 'flex', mb: '1.5rem', mt: '1.5rem', justifyContent: 'flex-end' }} bottom={0} position='fixed' width='820px'>
                    <Button
                      sx={{ borderRadius: '0.375rem' }}
                      color='secondary'
                      variant='contained'
                      startIcon={<PrinterIcon />}
                      disabled={usersPermissions.printRapsheetPDF ? rapbackPdfURL.length > 0 ? false : !usersPermissions.printRapsheetPDF ? false : true : false}
                      onClick={(rapbackPdfURL.length > 0 && usersPermissions.printRapsheetPDF) ? printRapsheetPDF : printRapbackDetails}
                    >
                      PRINT
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Drawer>
            {
              tableHeaderCells && (
                <CustomTable
                  tableConfig={tableConfig}
                  tableName="rapback-summary"
                  loading={loading}
                  rows={data?.length ? data : []}
                  orderBy={orderBy}
                  order={sortOrder}
                  totalRecords={totalRecords}
                  currentPage={page}
                  pageSize={rowsPerPage}
                  searched={searched}
                  value={value}
                  handleRequestSort={handleRequestSort}
                  setValue={setValue}
                  setSearched={setSearched}
                  defaultSortColumn={orderBy}
                  savedSearchState={[{}, () => { }]}
                  handleClickShowSSN={handleClickShowSSN}
                  displaySSN={displaySSN}
                  rowClickHandler={viewRapBack}
                  openModalBlur={openRapbackDrawer}
                />
              )
            }

          </Paper>
        )

      }
    </>
  )
}
