export const Typography = {
  fontFamily: `"Montserrat", sans-serif`,
  h3: {
    fontSize: '2.25rem',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '116.7%',
    letterSpacing: '0.01563rem'
  },
  h4: {
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '123.5%',
    letterSpacing: '0.01563rem'
  },
  h5: {
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133.4%'
  },
  h6: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "28.8px",
    letterSpacing: "1.5px"
  },
  body1: {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "0.00938rem"
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: "143%",
    letterSpacing: "0.01063rem"
  },
  subtitle1: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: "175%",
    letterSpacing: "0.00938rem"
  },
  caption: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: "166%",
    letterSpacing: "0.025rem"
  },
}
