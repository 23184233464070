export const NotesStyle = {
    noNotesStyle: {
        display: 'flex',
        padding: '0rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
        alignSelf: 'stretch',
    },
    notesLoadingContainer: {
        display: 'flex',
        padding: '1.5rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        alignSelf: 'stretch',
        backgroundColor: 'common.black'
    },
    notesBodyStyle: {
        display: 'flex',
        padding: '1.5rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1rem',
        alignSelf: 'stretch',
        backgroundColor: 'common.black'
    },
    notesContainerStyle: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '1.5rem',
        alignSelf: 'stretch',
    },
    deleteModalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: '0.5rem'
    }, 
    notesCardStyle: {
        width: '100%',
        padding: 0,
        borderRadius: '0.375rem',
        overflowY: 'auto',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
    },
    avatarStyle: {
        width: 24, 
        height: 24, 
        fontSize: 12, 
        color: '#FFF'
    }
}