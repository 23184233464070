import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography, CircularProgress } from '@mui/material'
import { useMUITheme } from '../theme/ThemeProvider';
import { useUserManagementStore } from 'pages/UserManagement/state';

interface ConfirmModalProps {
    title: string
    content: string
    cancelButtonText: string
    confirmButtonText: string
    handleCancel: any
    handleConfirm: any
    openDialog: boolean
    children?
    customWidth?: any
    customHeight?: any
    openAddNewUser?: boolean
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    title,
    content,
    cancelButtonText,
    confirmButtonText,
    handleCancel,
    handleConfirm,
    openDialog,
    children,
    customWidth,
    customHeight,
    openAddNewUser
}) => {
    const { mode } = useMUITheme();
    const disableAddUser = useUserManagementStore(state => state.disableAddUser)
    const addNewUserLoading = useUserManagementStore(state => state.addNewUserLoading)

    return (
        <Dialog PaperProps={(customWidth && customHeight) && {
            sx: {
                minWidth: customWidth, minHeight: customHeight,
            }
        }} open={openDialog} >
            <Paper sx={(customWidth && customHeight) && {
                minWidth: customWidth, minHeight: customHeight, bgcolor: 'background.default'
            }}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent sx={{ height: "100%", paddingRight: '2.5rem' }}>
                    <Typography>
                        {content}
                    </Typography>
                    {children}
                </DialogContent>
                <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
                    <Button onClick={() => handleCancel()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '#000' }}>
                        {cancelButtonText}
                    </Button>
                    <Button onClick={() => handleConfirm()} variant="contained" color='secondary' disabled={openAddNewUser ? disableAddUser : false} sx={{width: 'auto'}}>
                        {addNewUserLoading ? <CircularProgress size='1.5rem' sx={{ color: '#fff' }}/> : confirmButtonText}
                    </Button>
                </DialogActions>
            </Paper>
        </Dialog>
    )
}